/* eslint-disable no-unused-expressions */
export const GetRoles = (accountType) => {
    const categorizedRoles = {
      read: [],
      create: [],
      edit: [],
      delete: [],
      import: [],
      export: [],
    };
  
     accountType?.roleBase?.forEach((roleType) => {
      let roleName = roleType?.name
      
     roleType.roleTypes.forEach((perm) => {
        switch (roleName?.toLowerCase()) {
          case "create":
            categorizedRoles.create.push(perm.name);
            break;
          case "read":
            categorizedRoles.read.push(perm.name);
            break;
          case "edit":
            categorizedRoles.edit.push(perm.name);
            break;
          case "delete":
            categorizedRoles.delete.push(perm.name);
            break;
          case "import":
            categorizedRoles.import.push(perm.name);
            break;
            case "export":
              categorizedRoles.export.push(perm.name);
              break;
          default:
            break;
        }
      });
    });
  
    return categorizedRoles;
  };