export const Authority = {
  ContentManagement: "CM000",
  ServiceManagement: "S000",
  ServiceList: "S001",
  ServiceInsert: "S002",
  AdvertisementManagement: "AD000",
  AdvertisementList: "AD001",
  AdvertisementDetail: "AD002",
  AdvertisementInsert: "AD003",
  AdvertisementUpdate: "AD004",
  AdvertisementDelete: "AD005",
  AnnouncementManagement: "AN000",
  AnnouncementList: "AN001",
  AnnouncementDetail: "AN002",
  AnnouncementInsert: "AN003",
  AnnouncementUpdate: "AN004",
  AnnouncementDelete: "AN005",
  LanguageManagement: "L000",
  LanguageList: "L001",
  LanguageDetail: "L002",
  LanguageInsert: "L004",
  LanguageUpdate: "L003",
  LanguageDelete: "L005",
  FoodManagement: "F000",
  FoodList: "F001",
  FoodDetail: "F002",
  FoodUpload: "F003",
  ReservationManagement: "R000",
  ReservationList: "R001",
  ReservationDetail: "R002",
  ReservationInsert: "R003",
  ReservationUpdate: "R004",
  ReservationDelete: "R005",
  ShortcutManagement: "SH000",
  ShortcutList: "SH001",
  ShortcutDetail: "SH002",
  ShortcutInsert: "SH003",
  ShortcutUpdate: "SH004",
  ShortcutDelete: "SH005",
  NewsManagement: "N000",
  NewsList: "N001",
  NewsDetail: "N002",
  NewsInsert: "N003",
  NewsUpdate: "N004",
  NewsDelete: "N005",
  QuestionnaireManagement: "Q000",
  QuestionnaireList: "Q001",
  QuestionnaireDetail: "Q002",
  QuestionnaireInsert: "Q003",
  QuestionnaireUpdate: "Q004",
  QuestionnaireDelete: "Q005",
  QuestionnaireReport: "Q006",
  QuestionnaireCategoryManagement: "QC000",
  QuestionnaireCategoryInsert: "QC001",
  QuestionnaireCategoryUpdate: "QC002",
  QuestionnaireCategoryDelete: "QC003",
  NotificationManagement: "NO000",
  NotificationList: "NO001",
  NotificationDetail: "NO002",
  NotificationInsert: "NO003",
  NotificationUpdate: "NO004",
  NotificationDelete: "NO005",
  EducationManagement: "E000",
  EducationList: "E001",
  EducationDetail: "E002",
  EducationInsert: "E003",
  EducationUpdate: "E004",
  EducationDelete: "E005",
  EducationReport: "E006",
  EducationCategoryManagement: "EC000",
  EducationCategoryInsert: "EC001",
  EducationCategoryUpdate: "EC002",
  EducationCategoryDelete: "EC003",
  ContractedCompanyManagement: "CCM000",
  ContractedCompanyList: "CCM001",
  ContractedCompanyDetail: "CCM002",
  ContractedCompanyInsert: "CCM003",
  ContractedCompanyUpdate: "CCM004",
  ContractedCompanyDelete: "CCM005",
  ContractedCompanyCategoryManagement: "CCCM000",
  ContractedCompanyCategoryInsert: "CCCM001",
  ContractedCompanyCategoryUpdate: "CCCM002",
  ContractedCompanyCategoryDelete: "CCCM003",
  SocialClubManagement: "SC000",
  SocialClubList: "SC001",
  SocialClubDetail: "SC002",
  SocialClubInsert: "SC003",
  SocialClubUpdate: "SC004",
  SocialClubDelete: "SC005",
  FeedbackReportManagement: "FBR000",
  FeedbackReportList: "FBR001",
  FeedbackReportDetail: "FBR002",
  UserandRoleManagementOperations: "UR000",
  UserManagement: "U000",
  UserList: "U001",
  UserDetail: "U002",
  UserUpdate: "U003",
  RoleManagement: "RL000",
  RoleList: "RL001",
  RoleDetail: "RL002",
  RoleInsert: "RL003",
  RoleUpdate: "RL004",
  RoleDelete: "RL005",
  TenantManagement: "T000",
  TenantList: "T001",
  TenantDetail: "T002",
  TenantInsert: "T003",
  TenantUpdate: "T004",
  SpecialTargetManagement: "ST000",
  SpecialTargetList: "ST001",
  SpecialTargetDetail: "ST002",
  SpecialTargetInsert: "ST003",
  SpecialTargetUpdate: "ST004",
  SpecialTargetDelete: "ST005",
  Home: "H000",
  ProjectDownloadUrlManagement: "PDU000",
  ProjectDownloadUrlList: "PDU001",
  ProjectDownloadUrlDetail: "PDU002",
  ProjectDownloadUrlUpdate: "PDU003",
  WomanManagement: "W000",
  WomanList: "W001",
  WomanDetail: "W002",
  WomanInsert: "W003",
  WomanUpdate: "W004",
  WomanDelete: "W005",
  NearMissManagement: "NM000",
  NearMissList: "NM001",
  NearMissDetail: "NM002",
  NearMissInsert: "NM003",
  NearMissUpdate: "NM004",
  NearMissDelete: "NM005", 
  PhoneBookManagement: "PD000",
  PhoneBookList: "PD001",
  PhoneBookDetail: "PD002",
  PhoneBookInsert: "PD003",
  PhoneBookUpdate: "PD004",
  PhoneBookDelete: "PD005",
  AppManagement: "PDU000",
  AppList: "PDU001",
  AppDetail: "PD002",
  AppUpdate: "PDU002",
  StepCounterManagement: "ST000",
  StepCounterList: "ST001",
  StepCounterDetail: "ST002",
  StepCounterInsert: "ST003",  
  StepCounterUpdate: "ST004",
  StepCounterDelete: "ST004",

  CompanyCreate : "cmp.01",
  CompanyRead : "cmp.02",
  CompanyEdit : "cmp.03",
  CompanyDelete : "cmp.04",
  CompanyImport : "cmp.05",
  CompanyExport : "cmp.06",

  AccountCreate : "acc.01",
  AccountRead : "acc.02",
  AccountEdit : "acc.03",
  AccountDelete : "acc.04",
  AccountImport : "acc.05",
  AccountExport : "acc.06",

  UserCreate : "usr.01",
  UserRead : "usr.02",
  UserEdit : "usr.03",
  UserDelete : "usr.04",
  UserImport : "usr.05",
  UserExport : "usr.06",

  OrganizationCreate : "org.01",
  OrganizationRead : "org.02",
  OrganizationEdit : "org.03",
  OrganizationDelete : "org.04",
  OrganizationImport : "org.05",
  OrganizationExport : "org.06",

  OkrCreate : "okr.01",
  OkrRead : "okr.02",
  OkrEdit : "okr.03",
  OkrDelete : "okr.04",
  OkrImport : "okr.05",
  OkrExport : "okr.06",

  ServiceCreate : "srv.01",
  ServiceRead : "srv.02",
  ServiceEdit : "srv.03",
  ServiceDelete : "srv.04",
  ServiceImport : "srv.05",
  ServiceExport : "srv.06",

  PositionCreate : "pos.01",
  PositionRead : "pos.02",
  PositionEdit : "pos.03",
  PositionDelete : "pos.04",
  PositionImport : "pos.05",
  PositionExport : "pos.06",

  OlaCreate : "ola.01",
  OlaRead : "ola.02",
  OlaEdit : "ola.03",
  OlaDelete : "ola.04",
  OlaImport : "ola.05",
  OlaExport : "ola.06",

  SettingCreate : "stg.01",
  SettingRead : "stg.02",
  SettingEdit : "stg.03",
  SettingDelete : "stg.04",
  SettingImport : "stg.05",
  SettingExport : "stg.06",

  KpiCreate : "kpi.01",
  KpiRead : "kpi.02",
  KpiEdit : "kpi.03",
  KpiDelete : "kpi.04",
  KpiImport : "kpi.05",
  KpiExport : "kpi.06",

  UcCreate : "uc.01",
  UcRead : "uc.02",
  UcEdit : "uc.03",
  UcDelete : "uc.04",
  UcImport : "uc.05",
  UcExport : "uc.06",
  
  SlaCreate : "sla.01",
  SlaRead : "sla.02",
  SlaEdit : "sla.03",
  SlaDelete : "sla.04",
  SlaImport : "sla.05",
  SlaExport : "sla.06",

  TargetCreate : "targ.01",
  TargetRead : "targ.02",
  TargetEdit : "targ.03",
  TargetDelete : "targ.04",
  TargetImport : "targ.05",
  TargetExport : "targ.06",
 

};
