/**
 *
 * @param {string} urlString
 * @param {Object} params
 * @param {'value'} params.key
 * @example getUrlWithParams('/address/streets', { cityCode: '1', districtCode: '4' })
 * @returns {String}
 */

export function  dateConverter(date) {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const dateString = date;
    const newDate = new Date(dateString) + oneDayInMilliseconds;
    const originalDate = new Date(newDate);
    originalDate.setDate(originalDate.getDate() + 1);
    const year = originalDate.getFullYear();
    const month = ("0" + (originalDate.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ("0" + originalDate.getDate()).slice(-2);

    const hours = ("0" + originalDate.getHours()).slice(-2);
    const minutes = ("0" + originalDate.getMinutes()).slice(-2);
    const seconds = ("0" + originalDate.getSeconds()).slice(-2);

    const convertDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return convertDate;
};
