/* eslint-disable array-callback-return */
import React from "react";
import styled from "styled-components";
import Input from "./Input";
import CustomDropdown from "./Dropdown";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import CustomCalendar from "./CustomCalendar";
import { formatDate } from "../utils/dateFormat";
import InputNumber from "./InputNumber";

const Container = styled.div.attrs({
  className: "flex align-items-center justify-content-center flex-wrap mt-5",
})`
  gap: 25px;
`;

function SearchBar({ items, handleSearch, header, button, disabled, downloadExcelData, excelButtonEnable=false  }) {
  const { t } = useTranslation();
console.log(items)
console.log(handleSearch)
  const getValues = () => {
    let values = {};

    items.forEach((i) => {
      if (i.component === "calendar") {
        if (i.selectionMode === "range") {
          i.names.forEach((field, index) => {
            values[field] = "";
          });
        } else {
          values[i.name] = "";
        }
      } else {
        values[i.name] = "";
      }
    });

    return values;
  };

  const formik = useFormik({
    initialValues: getValues(),
    onSubmit: async (data) => {
      const payload = {};
      await Object.keys(data).forEach((key) => {
        if (typeof data[key] === "object") {
          if (!data[key]) {
            return payload[key] === "";
          }
          payload[key] = formatDate(data[key]);
        } else {
          payload[key] = data[key];
        }
      });
      await handleSearch(payload);
    },
  });

  const handleReset = () => {
    formik.resetForm();
    formik.handleSubmit();
  };

  const handleChangeCalendar = (e, item) => {
    if (item.names) {
      item.names.forEach((i, index) => {
        formik.setFieldValue(i, e.value[index]);
      });
    } else {
      formik.setFieldValue(item.name, e.value);
    }
  };

  const setValue = (item) => {
    if (item.names) {
      const formatValue = item.names.map((i) => formik.values[i]);
      if (formatValue.filter((i) => i === "").length === 2) {
        return null;
      } else {
        return formatValue;
      }
    } else {
      return formik.values[item.name];
    }
  };

  return (
    <div className="card">
      {header && (
        <>
          <h5 className="m-0">{t("general.search", "Search")}</h5>
          <Divider />
        </>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Container>
          {items?.map((item, idx) => {
            // if (formik.values["startDate"] > 0) {
            //   item.disabled = false;
            // }

            switch (item.component) {
              case "input":
                return <Input key={idx} {...item} value={formik.values[item.name]} onChange={formik.handleChange} />;
              case "inputNumber":
                return <InputNumber key={idx} {...item} value={formik.values[item.name]} onChange={formik.handleChange} />;
              case "dropdown":
                return <CustomDropdown key={idx} {...item} value={formik.values[item.name]} onChange={formik.handleChange} />;
              case "calendar":
                return <CustomCalendar key={idx} {...item} minDate={formik.values[item.minDate]} value={setValue(item)} onChange={(event) => handleChangeCalendar(event, item)} />;
              case "calendarStart":
                return <CustomCalendar key={idx} {...item} value={setValue(item)} maxDate={formik.values[item.maxDate]} onChange={(event) => handleChangeCalendar(event, item)} />;
              default:
                break;
            }
          })}
        </Container>
        <div className="flex justify-content-end align-items-center mt-5" style={{ gap: "10px" }}>
          <Button type="button" label={t("general.reset", "Reset")} onClick={() => handleReset()} className="p-button-raised p-button-text" />
          <Button type="submit" label={t("general.search", "Search")} disabled={disabled ? disabled : false} />
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
