import { useContext } from "react";
import { ToasterContext } from "../context/ToasterContext";


/**
 *
 * @param {string} urlString
 * @param {Object} params
 * @param {'value'} params.key
 * @example getUrlWithParams('/address/streets', { cityCode: '1', districtCode: '4' })
 * @returns {String}
 */
export function ImageSizeController(data, openToaster) {
  const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
  const invalidPhotos = data.filter((image) => image.size > maxSizeInBytes);
  let toasterShown = false;

  if (invalidPhotos.length > 0) {
    invalidPhotos.forEach((photo) => {
      if (!toasterShown) {
        openToaster("bottom-right", { severity: "error", summary: photo.name, detail: "Dosya Boyutunu aşmaktadır", life: 3000 });  
      }
      toasterShown = true;
    });
    return null;
  }

  return 0; // Replace with the desired return value
}
