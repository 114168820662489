/* eslint-disable array-callback-return */
// import { Editor } from "primereact/editor";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Mode } from "../utils/mode";
import CustomCalendar from "./CustomCalendar";
import CustomMultiSelect from "./CustomMultiSelect";
import CustomDropdown from "./Dropdown";
import ExcelUpload from "./ExcelUpload";
import FileUpload from "./FileUpload";
import Input from "./Input";
import Textarea from "./Textarea";
import { Editor } from "primereact/editor";
import CustomFileUpload2 from "./FileUpload2";
import Inputmask from "./InputMask";
import InputNumber from "./InputNumber";
import InputTextArea from "./InputTextArea";
import PasswordInput from "./Password";
import InputPhonemask from "./PhoneVerification";
import CustomFileUploadStory from "./FileUploadStory";
import CustomInputSwitch from "./CustomInputSwitch";

const CustomForm = ({ items, data, handleChange, mode, isColumn, width, url, gap }) => {
  let { values, touched, errors } = data;
  const [isEditorShow, setEditorShow] = useState(false);

  const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error ml-2">{errors[name]}</small>;
  };

  useEffect(() => {
    setTimeout(() => {
      setEditorShow(true);
    }, 2000);
  }, []);

  return (
    <div className={isColumn ? "flex flex-column" : "formgrid grid pt-3 margin-clear"} style={{ gap: isColumn && gap, width: isColumn && width }}>
      {items?.map((item, idx) => {
        const { name, label, tooltip, maxLength, keyfilter, isVertical, isStory, type, containerClassName, url, rows, cols } = item;

        const disable = item?.disable ? item.disable : mode === Mode.DETAIL || mode === "detail";
        switch (item.component) {
          case "input":
            return (
              <div className={classNames({ "field mb-5 col md:col-6": !isColumn })} key={idx}>
                <Input
                  key={idx}
                  disabled={disable}
                  errorMsg={getFormErrorMessage(name)}
                  containerClassName={containerClassName}
                  label={label}
                  type={type}
                  name={name}
                  tooltip={tooltip}
                  tooltipOptions={"top"}
                  value={values[item.name] || ""}
                  onChange={handleChange}
                  className="w-full modal-input-height"
                  maxLength={maxLength}
                  keyfilter={keyfilter}
                />
              </div>
            );
          case "inputBig":
            return (
              <div className={classNames({ "field mb-5 col md:col-12": !isColumn })} key={idx}>
                <InputTextArea
                  key={idx}
                  disabled={disable}
                  errorMsg={getFormErrorMessage(name)}
                  label={label}
                  name={name}
                  tooltip={tooltip}
                  tooltipOptions={"top"}
                  value={values[item.name] || ""}
                  onChange={handleChange}
                  className="w-full modal-input-height2"
                  rows={rows}
                  cols={cols}
                  maxLength={maxLength}
                  keyfilter={keyfilter}
                />
              </div>
            );
          case "inputNumber":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <InputNumber
                  inputId="withoutgrouping"
                  useGrouping={false}
                  key={idx}
                  disabled={disable}
                  errorMsg={getFormErrorMessage(name)}
                  label={label}
                  name={name}
                  tooltip={tooltip}
                  tooltipOptions={"top"}
                  value={values[item.name] || null}
                  onChange={handleChange}
                  className="w-full modal-input-height"
                  maxLength={maxLength}
                  keyfilter={keyfilter}
                />
              </div>
            );
          case "inputMask":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <Inputmask key={idx} disabled={disable} errorMsg={getFormErrorMessage(name)} label={label} name={name} tooltip={tooltip} tooltipOptions={"top"} value={values[item.name] || ""} onChange={handleChange} className="w-full" maxLength={maxLength} keyfilter={keyfilter} />
              </div>
            );
          case "password":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <PasswordInput
                  key={idx}
                  disabled={disable}
                  errorMsg={getFormErrorMessage(name)}
                  containerClassName={containerClassName}
                  label={label}
                  name={name}
                  tooltip={tooltip}
                  tooltipOptions={"top"}
                  value={values[item.name] || ""}
                  onChange={handleChange}
                  className="w-full modal-input-height"
                  maxLength={maxLength}
                  keyfilter={keyfilter}
                />
              </div>
            );
          case "inputMaskPhone":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <InputPhonemask key={idx} disabled={disable} errorMsg={getFormErrorMessage(name)} label={label} name={name} tooltip={tooltip} tooltipOptions={"top"} value={values[item.name] || ""} onChange={handleChange} className="w-full" maxLength={maxLength} keyfilter={keyfilter} />
              </div>
            );
          case "editor":
            return mode === Mode.DETAIL || mode === "detail" || mode === "edit" || mode === Mode.EDIT
              ? isEditorShow && (
                  <>
                    <h6 className={classNames({ "field mb-1 col md:col-12": !isColumn })}>{item?.label}</h6>
                    <Editor
                      key={idx}
                      className={classNames({ "field mb-1 col md:col-12": !isColumn })}
                      label={item.label}
                      placeholder={item.label}
                      readOnly={disable}
                      maxLength={5000}
                      style={{ height: "200px" }}
                      name={item.name}
                      value={values[item.name] || ""}
                      onTextChange={(e) => data.setFieldValue(item.name, e.htmlValue)}
                    />
                    {/* Note: BUNU COMPONENT İÇERİSİNE TAŞI */}
                    {getFormErrorMessage(name) && getFormErrorMessage(name)}
                  </>
                )
              : isEditorShow && (
                  <>
                    <h6 className={classNames({ "field mb-1 col md:col-12": !isColumn })}>{item?.label}</h6>
                    <Editor
                      key={idx}
                      className={classNames({ "field mb-1 col md:col-12": !isColumn })}
                      label={item.label}
                      readOnly={disable}
                      placeholder={item.label}
                      maxLength={5000}
                      style={{ height: "200px" }}
                      name={item.name}
                      value={values[item.name] || ""}
                      onTextChange={(e) => data.setFieldValue(item.name, e.htmlValue)}
                    />
                    {/* Note: BUNU COMPONENT İÇERİSİNE TAŞI */}
                    {getFormErrorMessage(name) && getFormErrorMessage(name)}
                  </>
                );
          case "calendar":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <CustomCalendar
                  {...item}
                  key={idx}
                  disabled={disable}
                  label={item.label}
                  name={item.name}
                  minDate={item?.minDate ?? item.minDate}
                  value={values[item.name] ? new Date(values[item.name]) : ""}
                  onChange={handleChange}
                  errorMsg={getFormErrorMessage(item.name) || ""}
                  className="w-full modal-calender-height"
                  timeOnly={item.timeOnly}
                />
              </div>
            );
          case "calendarStart":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <CustomCalendar
                  {...item}
                  key={idx}
                  disabled={disable}
                  label={item.label}
                  name={item.name}
                  minDate={item?.minDate ? item.minDate : new Date()}
                  value={values[item.name] ? new Date(values[item.name]) : ""}
                  onChange={handleChange}
                  view={item.view}
                  maxDate={values?.[item?.maxDate] ? values?.[item?.maxDate] : item?.maxDate}
                  errorMsg={getFormErrorMessage(item.name) || ""}
                  className="w-full modal-calender-height"
                />
              </div>
            );
          case "textarea":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <Textarea key={idx} disabled={disable} label={item.label} name={item.name} tooltip={item.tooltip} tooltipOptions={"top"} value={values[item.name]} onChange={handleChange} errorMsg={getFormErrorMessage(item.name)} className="w-full modal-input-height" rows="10" maxLength={2000} />
              </div>
            );
          case "dropdown":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <CustomDropdown key={idx} disabled={disable} {...item} label={item.label} name={item.name} filter={item.filter} filterPlaceholder={item.filterPlaceholder} value={values[item.name]} onChange={handleChange} errorMsg={getFormErrorMessage(item.name)} className="w-full modal-dropdown-height" rows="10" maxLength={200} />
              </div>
            );
          case "multiselect":
            return (
              <div className={classNames({ "field mb-4 col md:col-6": !isColumn })} key={idx}>
                <CustomMultiSelect key={idx} disabled={disable} {...item} label={item.label} name={item.name} value={values[item.name]} onChange={handleChange} errorMsg={getFormErrorMessage(item.name)} className="w-full modal-input-height" filter rows="10" maxLength={200} />
              </div>
            );
          case "inputswitch":
            return (
              <div className={classNames({ "field col md:col-12": !isColumn })} key={idx}>
                <CustomInputSwitch key={idx} disabled={disable} {...item} label={item.label} name={item.name} value={values[item.name]} onChange={handleChange} errorMsg={getFormErrorMessage(item.name)} className="w-full modal-input-height" />
              </div>
            );
          case "fileUpload":
            const handleFile = (e) => {
              data.setFieldValue(item.name, e);
            };
            return (
              <>
                <div className={classNames({ "field mb-4 mt-4 col md:col-12": !isColumn })} key={idx}>
                  <h5 className={classNames({ "field mb-3 col md:col-12": !isColumn })}>
                    <b>{item?.label}</b>
                  </h5>
                  <FileUpload
                    accept={item.accept}
                    mode={item.mode}
                    key={idx}
                    data={data}
                    disabled={disable}
                    {...item}
                    isVertical={item.isVertical}
                    isStory={item.isStory}
                    label={item.label}
                    name={item.name}
                    value={values[item.name]}
                    checked={values[0]}
                    onChange={handleFile}
                    errorMsg={(name) => getFormErrorMessage(name)}
                    className="w-full"
                  />
                </div>
              </>
            );
          case "fileUpload2":
            const handleFile3 = (e) => {
              data.setFieldValue(item.name, e);
            };
            return (
              <>
                <div className="company-modal-top-area">
                  <div className={classNames({ "col md:col-12": !isColumn })} key={idx}>
                    <CustomFileUpload2
                      accept={item.accept}
                      mode={item.mode}
                      url={url}
                      key={idx}
                      disabled={item?.disable || mode === Mode.DETAIL || mode === "detail"}
                      {...item}
                      label={item.label}
                      name={item.name}
                      isStory={isStory}
                      value={values[item.name]}
                      onChange={handleFile3}
                      errorMsg={getFormErrorMessage(item.name)}
                      className="w-full upload-company-logo"
                    />
                  </div>
                    <div className="flex justify-content-center align-items-center company-logo-title">{item?.label}</div>
                </div>

                {/* {url && (
                  <div>
                    <img src={`data:${url?.contentType};base64,${url?.data}`} alt="logo" width="80" height="80" style={{ marginRight: "1rem", borderRadius: "50%" }} />
                  </div>
                )} */}
              </>
            );
          case "fileUploadStory":
            const handleFileStory = (e) => {
              data.setFieldValue(item.name, e);
            };

            return (
              <div className={classNames({ "field mb-3 mt-3 col md:col-6": !isColumn })} key={idx}>
                <h5 className={classNames({ "field mb-1 col md:col-12": !isColumn })}>
                  <b>{item?.label}</b>
                </h5>
                <CustomFileUploadStory
                  accept={item.accept}
                  mode={item.mode}
                  key={idx}
                  disabled={item?.disable || mode === Mode.DETAIL || mode === "detail"}
                  {...item}
                  name={item.name}
                  isStory={isStory}
                  value={values[item.name]}
                  onChange={handleFileStory}
                  errorMsg={getFormErrorMessage(item.name)}
                  className="w-full"
                />
              </div>
            );
          case "excelUpload":
            const handleFile2 = (e) => {
              data.setFieldValue(item.name, e);
            };
            return (
              <div className={classNames({ "field mb-4 col md:col-12": !isColumn })} key={idx}>
                <ExcelUpload
                  accept={item.accept}
                  mode={item.mode}
                  key={idx}
                  url={url}
                  disabled={item?.disable || mode === Mode.DETAIL || mode === "detail"}
                  {...item}
                  label={item.label}
                  name={item.name}
                  value={values[item.name]}
                  onChange={handleFile2}
                  errorMsg={getFormErrorMessage(name)}
                  className="w-full"
                />
              </div>
            );
          default:
            break;
        }
      })}
    </div>
  );
};

export default CustomForm;
