import { tryCatch } from "@thalesrc/js-utils";
import { 
COPY_OR_CREATE_AGREEMENT,
BIND_KPI_TO_AGREEMENT,
SIGN_AGREEMENTS,
CREATE_AGREEMENT,
AGREEMENT_STATS
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function copyAgreement(payload) {
    const url = pathWithQueryString(COPY_OR_CREATE_AGREEMENT)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function createAgreement(payload) {
    const url = pathWithQueryString(CREATE_AGREEMENT)

    const [error, result] = await tryCatch(http.post(url, payload));
    console.log("CREATEAGGERR",error)
    console.log("CREATEAGGRES",result)
    if (error) throw error;
  
    return result.data.data;
}

export async function bindKpiToAgreement(payload) {
    const url = pathWithQueryString(BIND_KPI_TO_AGREEMENT)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function signAgreements(payload) {
    const url = pathWithQueryString(SIGN_AGREEMENTS)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}
export async function getAgreementStats(payload) {
  
    const url = pathWithQueryString(AGREEMENT_STATS);
  
    const [error, result] = await tryCatch(http.post(url,payload));
  
    if (error) throw error;
  
    return result.data;
  }