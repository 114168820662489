import { tryCatch } from "@thalesrc/js-utils";
import { 
    INSERT_PRIORITY,
    DELETE_PRIORITY,
    GET_ALL_PRIORITY_LIST,
    GET_PRIORITY_BY_ID,
    PURGE_DELETE_PRIORITY,
    UPDATE_PRIORITY
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertPriority(payload) {
    const url = pathWithQueryString(INSERT_PRIORITY)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deletePriority(payload) {
    const url = pathWithQueryString(DELETE_PRIORITY)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAllPriorityList(payload) {
    const url = pathWithQueryString(GET_ALL_PRIORITY_LIST, payload)

    const [error, result] = await tryCatch(http.get(url, payload));
    console.log("priority",result)
    if (error) throw error;
  
    return result.data;
}

export async function getPriorityById(payload) {
    const url = pathWithQueryString(GET_PRIORITY_BY_ID)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function purgeDeletePriority(payload) {
    const url = pathWithQueryString(PURGE_DELETE_PRIORITY)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updatePriority(payload) {
    const url = pathWithQueryString(UPDATE_PRIORITY)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}