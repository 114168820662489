import { tryCatch } from "@thalesrc/js-utils";
import {
    LOGIN_CORDLYNX,
    REFRESH_TOKEN,
    REGISTER_CORDLYNX,
    RESET_PASSWORD,
    SEND_EMAIL,
    SEND_EMAIL_CODE
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function loginUser(payload) {
    const url = LOGIN_CORDLYNX;
  
    const [error, result] = await tryCatch(
      http.post(url, payload, {
        headers: {
            "Accept": "application/json",
            'x-tenant': 'test'
          },
      },)
    );
  
    if (error) throw error;
  
    return result.data;
  }

  export async function insertUser(payload) {

    const [error, result] = await tryCatch(http.post(REGISTER_CORDLYNX, payload, {
      headers: {
          'Content-Type': 'multipart/form-data',
        },
    }));
  
    if (error) throw error;
  
    return result.data.data;
  }

  export async function refreshToken(payload) {
    const [error, result] = await tryCatch(http.post(REFRESH_TOKEN, payload));
  
    if (error) throw error;
  
    return result.data.data;
  }

  export async function sendEmail(payload) {
    const [error, result] = await tryCatch(http.post(SEND_EMAIL, payload));
  
    if (error) throw error;
  
    return result.data.data;
  }

  export async function sendEmailCode(payload) {
    const [error, result] = await tryCatch(http.post(SEND_EMAIL_CODE, payload));
  
    if (error) throw error;
  
    return result.data.data;
  }

  export async function newPassword(payload) {
    const [error, result] = await tryCatch(http.post(RESET_PASSWORD, payload));
  
    if (error) throw error;
  
    return result.data.data;
  }
