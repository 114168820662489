import classNames from "classnames";
import { FileUpload } from "primereact/fileupload";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import media from "../utils/media";

const Container = styled.div`
  height: 50%;
  width: 50%;
  display: column;
  justify-content: center;
  align-items: center;

  input {
    min-width: ${(p) => p.minWidth};

    ${media.xs`
      min-width: 100%;
    `}
  }
  .p-float-label {
    width: 100%;
  }

  ${({ mode }) =>
    mode === "basic" &&
    `
    .p-float-label, .p-fileupload-choose, .p-fileupload-basic{
    height: 150px;
    width: 150px;
    background: transparent;
    color: black;
    text-align:center;
    justify-content: center;
    border-radius: 50%;

    .p-button-label{
      flex: initial;
    }
  }
  .p-fileupload-basic {
    position: absolute;
    top:0;
    left:0;
  }
  .p-fileupload .p-fileupload-content {
  display: none;
}
  .p-fileupload-choose-selected{
    span{
      display: none !important;
    }
  }

  .p-fileupload-choose-selected:hover{
    span{
      display: block !important;
    }
  }
  `}
`;

function CustomFileUpload({ mode = "basic", errorMsg, accept, disabled, data, className, url, containerClassName, onChange, value, name, label, required = false, minWidth = "250px", emptyTemplate, idx }) {
  const [imgSrc, setimgSrc] = useState("");
  const [files, setFiles] = useState([]);
  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  const onBasicUpload = () => {
    toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded with Basic Mode" });
  };
  const headerTemplate = (options) => {
    const { className, chooseButton } = options;

    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
        {chooseButton}
      </div>
    );
  };

  const handleChange = (e) => {
    onChange(e.originalEvent.target.files[0]);
    const fileNames = [...e.files].filter((file) => !files.map((a) => a.name).includes(file.name));
    setFiles((prev) => [...prev, ...fileNames]);
  };
  const handleRemove = (event) => {
    onChange([]);
  };

  return (
    <Container minWidth={minWidth} className={containerClassName} isImg={imgSrc ? true : false} mode={mode} key={idx}>
      <span className="p-float-label">
        {imgSrc && <img style={{ objectFit: "cover", borderRadius: "50%" }} width="50%" height="50%" src={imgSrc} alt=""></img>}
        <FileUpload
          mode={mode}
          ref={fileUploadRef}
          disabled={disabled}
          id={name}
          value={value}
          onRemove={(e) => {
            handleRemove(e);
          }}
          name={name}
          onSelect={(e) => handleChange(e)}
          autoComplete="off"
          onUpload={onBasicUpload}
          headerTemplate={headerTemplate}
          onBeforeSend={() => {}}
          emptyTemplate={emptyTemplate}
          className={classNames(className, { "p-invalid": errorMsg })}
          accept={accept}
        />
        {url && (
          <div>
            <h5>{label}</h5>
            <a style={{ textDecorationLine: "underline" }} href={url}>
              Pdf için tıklayınız
            </a>
          </div>
        )}

        <label htmlFor={name}>
          {errorMsg && <span className="text-pink-500">*</span>}
          <div className="mt-4">
          {label}
          </div>
          
          <div style={{ display: "block", height: "40px", margin: "20px", fontSize: "20px" }}>{errorMsg && errorMsg}</div>
        </label>
      </span>
    </Container>
  );
}

export default CustomFileUpload;
