import classNames from "classnames";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import media from "../utils/media";
import { Checkbox } from "primereact/checkbox";
import { t } from "i18next";

const Container = styled.div`
  height: 50%;
  width: 50%;
  display: column;
  justify-content: center;
  align-items: center;

  input {
    min-width: ${(p) => p.minWidth};

    ${media.xs`
      min-width: 100%;
    `}
  }
  .p-float-label {
    width: 100%;
  }

  ${({ mode }) =>
    mode === "basic" &&
    `
    .p-float-label, .p-fileupload-choose, .p-fileupload-basic{
    height: 150px;
    width: 150px;
    background: transparent;
    color: black;
    text-align:center;
    justify-content: center;
    border-radius: 50%;

    .p-button-label{
      flex: initial;
    }
  }
  .p-fileupload .p-fileupload-content {
  display: none;
}
  .p-fileupload-basic {
    position: absolute;
    top:0;
    left:0;
  }

  .p-fileupload-choose-selected{
    span{
      display: none !important;
    }
  }

  .p-fileupload-choose-selected:hover{
    span{
      display: block !important;
    }
  }
  `}
`;

function CustomFileUpload({ mode = "basic", errorMsg, accept, disabled, data, className, isVertical, isStory, containerClassName, onChange, value, name, label, required = false, minWidth = "250px", emptyTemplate, idx, oldImage }) {
  const fileUploadRef = useRef(null);
  const [allFile, setAllFile] = useState([]);
  const [allFile2, setAllFile2] = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [disabledImage, setDisabledImage] = useState(null);
  const [resetting, setResetting] = useState(false); // Add resetting flag
  const [fileUploadKey, setFileUploadKey] = useState(0);
  const [disabledImage2, setDisabledImage2] = useState(null);
  
  const imgUrl = process.env.REACT_APP_IMAGE_URL;
  useEffect(() => {
    setTimeout(() => {
      setIsSelect(true);
    }, 1000);

    const imageAllList = [...value, ...oldImage];
    setAllFile(imageAllList);
    setAllFile2(imageAllList);
    if (isSelect) {
      const currentValue = value.filter((file) => file.name === data.values.selectedImage);
      const oldValue = oldImage.filter((file) => file.imageUrl === data.values.selectedImage);
      if ((oldValue === undefined || oldValue?.length === 0) && (currentValue === undefined || currentValue?.length === 0)) {
        data.setFieldValue("selectedImage", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, oldImage]);

  // useEffect(() => {
  //   const verticalImage = allFile2.find((image) => image.isVertical === true);
  //   const selectedImage = allFile2.find((image) => image.isSelected === true);

  //   const imageUrl = verticalImage ? verticalImage.imageUrl : null;
  //   const imageUrlOld = selectedImage ? selectedImage.imageUrl : null;

  //   setSelectedCategory(imageUrl);
  //   setDisabledImage(imageUrl);
  //   setDisabledImage2(imageUrlOld);
  // }, [isVertical, allFile2]);

  const headerTemplate = (options) => {
    const { className, chooseButton } = options;

    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
        {chooseButton}
      </div>
    );
  };

  const handleChange = (event) => {
    const imageList = [...event.files].filter((file) => ![...value].map((a) => a.name).includes(file.name));
    const totalImage = [...value, ...imageList];
    if (totalImage?.length === 1) {
      if (totalImage[0]?.name && totalImage[0]?.name.length > 0) {
        data.setFieldValue("selectedImage", totalImage[0].name);
      }
    }
    onChange([...value, ...imageList]);
  };
  const handleRemove = (event) => {
    const filterImage = value.filter((image) => image.name !== event.file.name);
    onChange(filterImage);
  };
  // const handleDeleteImages = () => {
  //   if (!resetting) {
  //     setResetting(true);
  //     setFileUploadKey((prevKey) => prevKey + 1);
  //     onChange([]);
  //     setAllFile([]);
  //     setTimeout(() => {
  //       setResetting(false);
  //     }, 0);
  //   }
  // };
  // if (value?.[0]?.type === "image/*") {
  //   handleDeleteImages();
  // }

  return (
    <Container minWidth={minWidth} className={containerClassName} mode={mode} key={idx}>
      <span className="p-float-label">
        <FileUpload
          mode={mode}
          ref={fileUploadRef}
          // key={fileUploadKey} // Use key prop to force remount
          disabled={disabled}
          id={name}
          value={value}
          name={name}
          onRemove={(e) => {
            handleRemove(e);
          }}
          onSelect={(e) => handleChange(e)}
          autoComplete="off"
          onBeforeSend={() => {}}
          multiple
          headerTemplate={headerTemplate}
          emptyTemplate={emptyTemplate}
          className={classNames(className, { "p-invalid": errorMsg(name) })}
          accept={accept}
        />
        <div>
          <label className="ml-2 " htmlFor={name}>
            {/* {errorMsg && <span className="text-pink-500">*</span>} */}
            {/* {label} */}
            <div style={{ display: "block", height: "40px", margin: "20px", marginTop: "30px", fontSize: "20px" }}>{errorMsg(name) && errorMsg(name)}</div>
          </label>
        </div>

        <div className="mt-2 mb-2 font-bold">{t("general.recommendedImageSize")}</div>
      </span>

      {/* {isStory ? (
        <>
          <p className="mt-1 mb-1">Story Fotoğrafını Seçiniz</p>
          {a.map((category) => (
            <div key={category.name} className="field-radiobutton">
              <RadioButton
                inputId={category.name}
                name="category"
                value={category}
                onChange={(e) => {
                  setSelectedCategory(e.value.name);
                  data.setFieldValue("isVertical", category.name);
                  setDisabledImage(category.name);
                }}
                disabled={category.name === disabledImage2}
                checked={selectedCategory === category.name}
              />
              <label htmlFor={category.name.replace("http://10.1.23.234:8080/images/", "")}>{category.name.replace("http://10.1.23.234:8080/images/", "")}</label>
            </div>
          ))}
          
        </>
      ) : null} */}

      {data?.values?.selectedImage || data?.values?.eventAnnouncementImageList?.length > 0 ? (
        <>
          {/* {allFile.map((category, index) => {
            return (
              <>
                {category.name ? (
                  <div className="flex align-items-center mt-2">
                    <RadioButton
                      inputId={category.name}
                      checked={checked}
                      className="mt-2"
                      disabled={category.name === disabledImage2}
                      onChange={(e) => {
                        setChecked(e.checked);
                        data.setFieldValue("isVertical", category.name);
                        setDisabledImage(category.name);
                      }}
                    />
                    <label htmlFor={category.name} className="ml-2">
                      {category.name}
                    </label>
                  </div>
                ) : (
                  <div className="flex align-items-center mt-2">
                    <RadioButton
                      inputId={category.imageUrl}
                      checked={data.values.selectedImage === category.imageUrl}
                      className="mt-2"
                      disabled={category.name === disabledImage2}
                      onChange={(e) => {
                        setChecked(e.checked);
                        data.setFieldValue("isVertical", category.imageUrl);
                      }}
                    />
                    <label htmlFor={category.imageUrl} className="ml-2">
                      {category.imageUrl?.replace(imgUrl, "")}
                    </label>
                  </div>
                )}
              </>
            );
          })} */}
          {/* <div className="field-checkbox">
            <Checkbox inputId="binary" checked={checked} onChange={(e) => setChecked(e.checked)} />
            <label htmlFor="binary">İs Vertical</label>
          </div> */}
          <div className="mt-4 mb-2 font-bold">{t("general.homePagePhoto")}</div>{" "}
        </>
      ) : null}
      <div style={{ fontSize: "20px" }}>{data?.values?.selectedImage ? null : errorMsg("selectedImage") && errorMsg("selectedImage")}</div>

      {allFile.map((category, index) => {
        return (
          <>
            {category.name ? (
              <div key={category.name} className="flex align-items-center mt-2">
                <RadioButton
                  inputId={category.name}
                  checked={data.values.selectedImage === category.name}
                  className="mt-2"
                  disabled={category.name === disabledImage}
                  onChange={(e) => {
                    data.setFieldValue("selectedImage", category.name);
                    setDisabledImage2(category.name);
                  }}
                />
                <label htmlFor={category.name} className="ml-2">
                  {category.name}
                </label>
              </div>
            ) : (
              <div key={category.imageUrl} className="flex align-items-center mt-2">
                <RadioButton
                  inputId={category.imageUrl}
                  checked={data.values.selectedImage === category.imageUrl}
                  className="mt-2"
                  disabled={category.imageUrl === disabledImage}
                  onChange={(e) => {
                    data.setFieldValue("selectedImage", category.imageUrl);
                    setDisabledImage2(category.imageUrl);
                  }}
                />
                <label htmlFor={category.imageUrl} className="ml-2">
                  {category.imageUrl?.replace(imgUrl, "")}
                </label>
              </div>
            )}
          </>
        );
      })}
    </Container>
  );
}

export default CustomFileUpload;
