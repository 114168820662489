import React from "react";
import styled from "styled-components/macro";
import { InputSwitch } from "primereact/inputswitch";
import classNames from "classnames";

const Container = styled.div`
  .p-inputswitch {
    min-width: ${(p) => p.minWidth};
  }
`;

function CustomInputSwitch({ minWidth = "25px", name, label, onChange, value, className, errorMsg, disabled }) {
  return (
    <Container minWidth={minWidth}>
      <span className=" flex align-items-center">
        <div style={{ width: "50px" }}>
        <InputSwitch
          name={name}
          disabled={disabled}
          className={classNames(className, { "p-invalid": errorMsg })}
          checked={value}
          onChange={(e) => onChange({ target: { name, value: e.value } })}
        />
        </div>
        
        <h7 className="ml-2">{label}</h7>
        <label htmlFor={name}>
          {errorMsg && <span className="text-pink-500">*</span>}  
        </label>
      </span>
      {errorMsg && errorMsg}
    </Container>
  );
}

export default CustomInputSwitch;
