import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import http from "./service/http";
import { GET_LANGUAGE_ITEMS_BY_CODE } from "./utils/url";
import { tryCatch } from "@thalesrc/js-utils";


const formatLangItems = (data) => {
  const keyNames = Object.keys(data);
  const newlist = {};
  for (let index = 0; index < keyNames.length; index++) {
    const key = keyNames[index];
    const parent = key.split("_");
    const newName = `${parent[0]}.${parent[1]}`;
    newlist[newName] = data[key];
  }
  return newlist;
};

const loadResources = async (locale) => {
  const [error, result] = await tryCatch(http.get(GET_LANGUAGE_ITEMS_BY_CODE, { headers: { languageCode: locale.toUpperCase() } }));
  if (error) throw error;
  const translatedData = formatLangItems(result.data.data);
  return translatedData;
};

// const backendOptions = {
//   loadPath: "{{lng}}|{{ns}}",
//   request: (options, url, payload, callback) => {
//     try {
//       const [lng] = url.split("|");
//       loadResources(lng).then((response) => {
//         callback(null, {
//           data: response,
//           status: 200,
//         });
//       });
//     } catch (e) {
//       console.error(e);
//       callback(null, {
//         status: 500,
//       });
//     }
//   },
// };

const langDetectorOptions = {
  order: ["localStorage", "cookie", "htmlTag", "navigator"],

  lookupCookie: "locale",
  lookupLocalStorage: "locale",

  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"],

  checkWhitelist: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(backend)
  .init({
    fallbackLng: "en",
    debug: false,
    detection: langDetectorOptions,
    interpolation: {
      escapeValue: false,
    },
    // backend: backendOptions,
    react: {
      useSuspense: true,
    },
  });

export default i18n;
