import { tryCatch } from "@thalesrc/js-utils";
import { 
VERIFIED_SLA
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertVerifiedSla(payload) {
    const url = pathWithQueryString(VERIFIED_SLA)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteVerifiedSla(id) {
    const url = pathWithQueryString(VERIFIED_SLA + id)

    const [error, result] = await tryCatch(http.delete(url));

    if (error) throw error;

    return result.data.data;
}

export async function getVerifiedSla(payload) {
    const url = pathWithQueryString(VERIFIED_SLA)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateVerifiedSla(payload) {
    const url = pathWithQueryString(VERIFIED_SLA)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}
