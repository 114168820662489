import { useContext } from "react";
import { ToasterContext } from "../context/ToasterContext";

/**
 *
 * @param {string} urlString
 * @param {Object} params
 * @param {'value'} params.key
 * @example getUrlWithParams('/address/streets', { cityCode: '1', districtCode: '4' })
 * @returns {String}
 */
export function ToasterHelper(err) {
    const { openToaster } = useContext(ToasterContext);
    for (let index = 0; index < err.length; index++) {
        const errorItem = err[index];
        openToaster("bottom-right", { severity: "error", summary: errorItem.key, detail: errorItem.value, life: 3000 });
      }
  }