import { tryCatch } from "@thalesrc/js-utils";
import { 
    ACCOUNT_GROUP, 
    GET_ACCOUNT_GROUP_LIST, 
    ACCOUNT_TYPE, 
    GET_ACCOUNT_TYPE_LIST, 
    INSERT_ROLE_TO_ACCOUNT_TYPE, 
    DELETE_ROLE_FROM_ACCOUNT_TYPE, 
    ROLE, 
    GET_ROLE_LIST, 
    ROLE_TYPE, 
    GET_ROLE_TYPE_LIST, 
    UPDATE_ALL_ROLES} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertRoleToAccountType(payload) {
    const url = pathWithQueryString(INSERT_ROLE_TO_ACCOUNT_TYPE)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteRoleFromAccountType(payload) {
    const url = pathWithQueryString(DELETE_ROLE_FROM_ACCOUNT_TYPE)

    const [error, result] = await tryCatch(http.delete(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

// Account Group

export async function insertAccountGroup(payload) {
    const url = pathWithQueryString(ACCOUNT_GROUP)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteAccountGroup(payload) {
    const url = pathWithQueryString(ACCOUNT_GROUP)

    const [error, result] = await tryCatch(http.delete(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAccountGroup(payload) {
    const url = pathWithQueryString(ACCOUNT_GROUP)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateAccountGroup(payload) {
    const url = pathWithQueryString(ACCOUNT_GROUP)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAccountGroupList(payload) {
    const url = pathWithQueryString(GET_ACCOUNT_GROUP_LIST,payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data.data;
}

// Account Type

export async function insertAccountType(payload) {
    const url = pathWithQueryString(ACCOUNT_TYPE)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteAccountType(payload) {
    const url = pathWithQueryString(ACCOUNT_TYPE + payload.id)

    const [error, result] = await tryCatch(http.delete(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAccountType(payload,id) {
    const url = pathWithQueryString(ACCOUNT_TYPE + id,payload)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateAccountType(payload, id) {
    const url = pathWithQueryString(ACCOUNT_TYPE + id)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAccountTypeList(payload) {
    const url = pathWithQueryString(GET_ACCOUNT_TYPE_LIST,payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data.data;
}

// Role

export async function insertRole(payload) {
    const url = pathWithQueryString(ROLE)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteRole(payload) {
    const url = pathWithQueryString(ROLE)

    const [error, result] = await tryCatch(http.delete(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getRole(payload) {
    const url = pathWithQueryString(ROLE)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateRole(payload) {
    const url = pathWithQueryString(ROLE)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getRoleList(payload) {
    const url = pathWithQueryString(GET_ROLE_LIST)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

// Role Type

export async function insertRoleType(payload) {
    const url = pathWithQueryString(ROLE_TYPE)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteRoleType(payload) {
    const url = pathWithQueryString(ROLE_TYPE)

    const [error, result] = await tryCatch(http.delete(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getRoleType(payload) {
    const url = pathWithQueryString(ROLE_TYPE)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateRoleType(payload) {
    const url = pathWithQueryString(ROLE_TYPE)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getRoleTypeList(payload) {
    const url = pathWithQueryString(GET_ROLE_TYPE_LIST,payload)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateAllRoles(payload) {
    const url = pathWithQueryString(UPDATE_ALL_ROLES+ `${payload.accountTypeId}/role/add-or-update`)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}