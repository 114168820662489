import { tryCatch } from "@thalesrc/js-utils";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ActionButtons from "../../components/ActionButtons";
import SearchBar from "../../components/SearchBar";
import { LoadingContext } from "../../context/Loading";
import { ToasterContext } from "../../context/ToasterContext";
import { useFetch } from "../../hooks/use-fetch";
import { Authority } from "../../utils/authority";
import pageURL from "../../utils/pageUrls";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDialogState } from "../../hooks/use-dialog-state";
import { Dialog } from "primereact/dialog";
import CustomForm from "../../components/CustomForm";
import { getCompanyGroupList, getCompanyGroupListTable } from "../../service/cordlynxServices/companyGroup";
import { getCompany } from "../../service/cordlynxServices/company";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { deleteCompany, insertCompany } from "../../service/cordlynxServices/company";
import { classNames } from "primereact/utils";
import { UserContext } from "../../context/UserContext";
import { getAllSlaList, getAllSlaListByCompanyIdAndCompanyGroupId, insertSla } from "../../service/cordlynxServices/sla";
import { cloneKpi, getAllKpiAndDetailsList, getAllKpiList } from "../../service/cordlynxServices/kpi";
import { Dropdown } from "primereact/dropdown";
import { useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Card } from "primereact/card";
import { Tooltip } from "primereact/tooltip";
import { getAllKpiCategoryList, insertKpiCategory } from "../../service/cordlynxServices/kpiCategory";
import { getAllPriorityList } from "../../service/cordlynxServices/priority";
import { dateConverter } from "../../helpers/dateConverter";
import { bindKpiToAgreement, createAgreement } from "../../service/cordlynxServices/agreement";
import { max } from "moment/moment";
import { getBotAnswerById } from "../../service/cordlynxServices/botAnswer";
import { Calendar } from "primereact/calendar";
import { getAccountList } from "../../service/cordlynxServices/account";
import { formatDateTR, formatDateUTC, formattedDate } from "../../utils/dateFormatTR";
import { formatDate, render } from "@fullcalendar/core";
import { insertVerifiedSla } from "../../service/cordlynxServices/verifiedSla";
import { getAllSlaTypeList } from "../../service/cordlynxServices/slaType";
import { getOrganizationGroupList } from "../../service/cordlynxServices/organizationGroup";

export default function ChatbotManagement() {
  const { setLoading, hideLoading, showLoading } = useContext(LoadingContext);
  const { selectedFactory, setSelectedFactory } = useContext(AppContext);
  const { push } = useHistory();
  const [expandedRows, setExpandedRows] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [opened, open, close] = useDialogState();
  const { t } = useTranslation();
  const { mode } = useParams();
  const [visible, setVisible] = useState(false);
  const [first, setFirst] = useState(0);
  const { openToaster } = useContext(ToasterContext);
  const { userStorage } = useContext(UserContext);
  const [payload, setPayload] = useState({
    includes: "Companies&includes=OwnerCompany",
    filterByProviderConsumer: true,
  });

  const [payloadPriority, setPayloadPriority] = useState({
    deleted: false,
    take: 999,
    skip: 0,
  });
  //

  const [payloadUsers, setPayloadUsers] = useState({
    companyId: selectedFactory?.id,
    includes: "Permissions",
  });

  const [payloadOrganizationGroup, setPayloadOrganizationGroup] = useState({
     includes: "Organizations&includes=Positions",
      companyId: selectedFactory?.id });

  // CHATBOT STATE
  const bottomRef = useRef(null);
  const bottomRefChoice = useRef(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [allChosen, setAllChosen] = useState([]);
  const [chatbotData, setChatbotData] = useState([]);
  const [loopCounter, setLoopCounter] = useState(0);
  const [parentBotAnswerId, setParentBotAnswerId] = useState();
  const [nextQuestion, setNextQuestion] = useState(0);
  const [lastGetBotAnswerBody, setLastGetBotAnswerBody] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newAnswerName, setNewAnswerName] = useState("");
  const [showAnswerButtons, setShowAnswerButtons] = useState(false);
  const [confirmClick, setConfirmClick] = useState(false);
  const [agreementType, setAgreementType] = useState("SLA");
  const [okrTo, setOkrTo] = useState("ORGANIZATION");
  // CHATBOT STATE

  const [selectedCompany, setSelectedCompany] = useState();

  const [priority, reloadPriority] = useFetch(() => getAllPriorityList(payloadPriority), [], { setLoading, reloadDeps: [payloadPriority], deps: [payloadPriority] });

  const [slaTypes, reloadSlaTypes] = useFetch(() => getAllSlaTypeList({ deleted: false, take: 999, skip: 0 }), [], { setLoading, reloadDeps: [], deps: [] });
  const [organizationGroupList, reloadOrganizationGroupList] = useFetch(() => getOrganizationGroupList(payloadOrganizationGroup), [], { setLoading, reloadDeps: [payloadOrganizationGroup], deps: [payloadOrganizationGroup] });

  const [companyList, reloadcompanyList] = useFetch(() => getCompanyGroupList(payload), [], { setLoading, reloadDeps: [payload], deps: [payload] });
  const companies = companyList?.find((group) => group?.ownerCompany?.id === userStorage?.account?.company?.id)?.companies?.map((item) => ({ name: item?.name, id: item?.id, image: item?.image }));
  const [companyUser, reloadCompanyUser] = useFetch(selectedFactory ? () => getAccountList(payloadUsers) : () => {}, [], { reloadDeps: [payloadUsers], deps: [payloadUsers] });
  const companyGroup = companyList?.find((group) => group?.ownerCompany?.id === selectedCompany?.id);

  const serviceProvider = companyGroup?.providers;
  const serviceConsumer = companyGroup?.consumers;
  const organizationGroup = organizationGroupList?.[0];
  const organizations = organizationGroup?.organizations


useEffect(() => {
  setPayloadUsers((prevState) => ({
    ...prevState,
    companyId: selectedFactory?.id,
  }));

  setPayloadOrganizationGroup((prevState) => ({
    ...prevState,
    companyId: selectedFactory?.id,
  }));
  
}
, [selectedFactory])

useEffect(() => {
  formik.resetForm();
  setOkrTo("ORGANIZATION")
},[agreementType])


    /* eslint-disable no-unused-expressions */
    useEffect(() => {
      //scrool to bottom
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatbotData]);
  
    useEffect(() => {
      // 👇️ scroll to bottom every time messages change
      bottomRefChoice.current?.scrollIntoView({ behavior: "smooth" });
    }, [allChosen]);
    /* eslint-enable no-unused-expressions */
  
    useEffect(() => {
      if (selectedFactory) {
        setSelectedCompany(selectedFactory);
      } else {
        setSelectedCompany(null);
      }
    }, [selectedFactory]);

  const slaValidationSchema = Yup.object().shape({
    contractName: Yup.string().required("Contract name is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    partnerCompanyId: Yup.string().required("Partner company is required"),
    activity: Yup.string().required("Activity is required"),
    serviceType: Yup.string().required("Service type is required"),
    contractResponsibleId: Yup.string().required("Contract responsible is required"),
  });

  const okrValidationSchema = Yup.object().shape({
    contractName: Yup.string().required("Contract name is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    activity: Yup.string().required("Activity is required"),
    contractResponsibleId: Yup.string().required("Contract responsible is required"),
    ownerOrganizationId: okrTo === "ORGANIZATION" ? Yup.string().required("Owner organization is required") : Yup.string(),
    ownerAccountId: okrTo === "USER" ? Yup.string().required("Owner account is required") : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      contractName: "",
      startDate: new Date(),
      endDate: new Date(),
      partnerCompanyId: "",
      serviceType: "",
      activity: "",
      contractResponsibleId: "",
      ownerOrganizationId: "",
      ownerAccountId: "",
    },
    validationSchema: agreementType === "OKR" ? okrValidationSchema : slaValidationSchema,
    //enableReinitialize: true,
    onSubmit: async (data) => {
   
      const kpiList = [];

      const StartDateFirstDay = new Date(data.startDate);
      StartDateFirstDay.setDate(1);

      const endDateLastDay = new Date(data.endDate);
      endDateLastDay.setDate(new Date(endDateLastDay.getFullYear(), endDateLastDay.getMonth() + 1, 0).getDate());

      const currentStartDate = formatDateUTC(StartDateFirstDay);
      const currentEndDate = formatDateUTC(endDateLastDay);

      allChosen.map((kpi, index) => {
        let kpiObj = {};
        let name = kpi?.kpiName ? kpi.kpiName : "kpi-" + index + "-" + new Date().toDateString();
        let priorityId = kpi.flow.find((item) => item.botQuestionOptions.order === 2).answer.id;
        let kpiCategoryId = GetCategory(kpi.flow);
        let targetWeight = GetWeight(kpi.flow);
        let companyId = selectedCompany?.id;

        kpiObj = { ...kpiObj, name, priorityId, kpiCategoryId, targetWeight, companyId };
        kpiList.push(kpiObj);
      });

      const sendObj = {
        name: data.contractName,
        startDate: currentStartDate.toISOString(),
        endDate: currentEndDate.toISOString(),
        partnerCompanyId: data.partnerCompanyId === "" ? null : data.partnerCompanyId,
        isActive: data.activity,
        ownerAccountId: data.ownerAccountId === "" ? null : data.ownerAccountId,
        ownerOrganizationId: data.ownerOrganizationId === "" ? null : data.ownerOrganizationId,
        accountId: userStorage.account.id,
        companyId: selectedCompany?.id,
        slaTypeId: slaTypes?.find((item) => item.type?.toLowerCase() === agreementType?.toLowerCase())?.id,
        kpiList: kpiList,
      };

      console.log("AGREMENTOBJ", sendObj);
      showLoading();
      const [err, res] = await tryCatch(createAgreement(sendObj));

      if (err) {
        openToaster("bottom-right", { severity: "error", summary: "Failed", detail: "An unexpected error occurred", life: 3000 });
        return;
      }

      if (res) {
        const sendKpiObj = {
          agreementId: res.id,
          accountId: data.contractResponsibleId,
          createdBy: userStorage.account.id,
          isOwner: true,
        };

        const [kpiErr, kpiRes] = await tryCatch(insertVerifiedSla(sendKpiObj));

        if (kpiErr) {
          openToaster("bottom-right", { severity: "error", summary: "Failed", detail: "An unexpected error occurred", life: 3000 });
          hideLoading();
          return;
        }
        if (kpiRes) {
          openToaster("bottom-right", { severity: "success", summary: "Success", detail: "Agreement has been successfully related to contract responsible!", life: 3000 });
        }

        openToaster("bottom-right", { severity: "success", summary: "Success", detail: "Agreement has been successfully created!", life: 3000 });
        formik.resetForm();
        setAllChosen([]);
        RefreshChatbot();
        setConfirmClick(false);

      }

      hideLoading();
    },
  });

  console.log("ALLCHOSEN", allChosen);

  function GetCategory(flow) {
    let result = undefined;
    for (const item of flow) {
      if (item?.botQuestionOptions?.order === 0 || item?.botQuestionOptions?.order === 1) {
        result = item.answer.id;
      }
    }
    return result;
  }
  function GetWeight(flow) {
    let result = undefined;
    for (const item of flow) {
      if (item?.botQuestionOptions?.order === 3) {
        result = item.answer.value;
      }
    }
    return result;
  }

  const RefreshChatbot = () => {
    setChatbotData([]);
    setLoopCounter(loopCounter + 1);
    setNextQuestion(0)
  }

  async function CreateSlaAndCreateKpi() {
    // const slaTypeId = agreementTypes.find(item =>
    //   item.type === contractType
    // )?.id
    // let startDate = ""
    // let endDate = ""
    // if (metaDataType === "old") {
    //   startDate = oldSlaFormik.values.startDate
    //   endDate = oldSlaFormik.values.endDate
    // }
    // else if (metaDataType === "new") {
    //   startDate = formik.values.startDate
    //   endDate = formik.values.endDate
    // }
    // else {//if without sla
    //   startDate = withoutSlaFormik.values.startDate
    //   endDate = withoutSlaFormik.values.endDate
    // }
    // const endDateDayNumber = GetDays(endDate)
    // let bodyOfCreateSla = contractType === "OKR" ?
    //   {
    //     name: formik.values.slaName !== "" ? formik.values.slaName : "sla-" + CreateDate(),
    //     accountId: user.id,
    //     companyGroupId: formik.values.selectedCompanyGroup,
    //     companyId: formik.values.selectedCompany,
    //     ownerAccountId: formik.values.selectedUserOkrTarget === "" ? null : formik.values.selectedUserOkrTarget,
    //     ownerOrganizationId: formik.values.selectedOrganizationOkrTarget === "" ? null : formik.values.selectedOrganizationOkrTarget,
    //     slaTypeId: slaTypeId,
    //     startDate: startDate + "-01T00:00:00.000Z",
    //     endDate: endDate + "-" + endDateDayNumber + "T00:00:00.000Z"
    //   }
    //   :
    //   {
    //     name: formik.values.slaName !== "" ? formik.values.slaName : "sla-" + CreateDate(),
    //     accountId: user.id,
    //     companyGroupId: formik.values.selectedCompanyGroup,
    //     companyId: formik.values.selectedCompany,
    //     slaTypeId: slaTypeId,
    //     startDate: startDate + "-01T00:00:00.000Z",
    //     endDate: endDate + "-" + endDateDayNumber + "T00:00:00.000Z"
    //   }
    // const slaId = await CreateSla(bodyOfCreateSla);
    // if(formik.values.selectedAgreementOwner?.id){
    //   await VerifiedSla({
    //     slaId: slaId,
    //     accountId: formik.values.selectedAgreementOwner.id,
    //     createdBy: user.id,
    //     isOwner: true
    //   })
    // }
    // const promisesToAwait = [];
    // for (let i = 0; i < allChosen.length; i++) {
    //   promisesToAwait.push(AddKpi(allChosen[i], slaId));
    // }
    // promisesToAwait.push(RefreshCreateOperation())
    // const responses = await Promise.all(promisesToAwait);
  }

  const AskWhetherToSave = () => {
    //add "Seçimleriniz kayıt edilsin mi?" question
    let temp = [...chatbotData];
    temp.push({
      botQuestionOptions: {
        id: null,
        description: {
          type: null,
          query: null,
          queryResult: {
            id: null,
            result: null,
          },
          values: [
            {
              id: null,
              value: "Evet",
            },
            {
              id: null,
              value: "Hayır",
            },
          ],
        },
        parentBotAnswer: {
          id: null,
          description: {
            type: null,
            query: null,
            queryResult: {
              id: null,
              result: null,
            },
            values: [],
          },
          parentBotAnswer: null,
          botQuestion: null,
          order: 3,
          isRecursive: false,
        },
        botQuestion: {
          id: null,
          question: "Seçimleriniz kayıt edilsin mi?",
        },
        order: -1, // if negative added this question and answer on frontend side
        isRecursive: false,
      },
      answer: undefined,
    });
    setChatbotData(temp);
  };

  useEffect(() => {
    //for first fetch operation
    GetBotAnswer({
      includeParents: true,
      includeQuestions: true,
      getAll: false,
      queryParameters: {
        CompanyId: selectedFactory?.id,
      },
    });
  }, [loopCounter]);

  const GetBotAnswer = async (body, updateFlag) => {
    //update flag for when add new answer as a category

    const [err, res] = await tryCatch(getBotAnswerById(body));

    if (err) {
      hideLoading();
      openToaster("bottom-right", { severity: "error", summary: err?.response?.data?.externalResponseCodeName, detail: err?.response?.data?.message, life: 3000 });
      return;
    }

    if (res) {
      let temp = lastGetBotAnswerBody;
      temp.push(body);
      setLastGetBotAnswerBody([...temp]);

      if (res.botAnswers === null) {
        AskWhetherToSave();
      } else if (res?.botAnswers?.[0]?.description?.values.length === 0) {
        setParentBotAnswerId(res?.botAnswers?.[0].id);
        setNextQuestion(nextQuestion + 1);
      } else {
        if (updateFlag) {
          let temp = [...chatbotData];
          temp[temp.length - 1] = {
            botQuestionOptions: res?.botAnswers?.[0],
            answer: undefined,
          };
          setChatbotData(temp);
        } else {
          let temp = [...chatbotData];
          temp.push({
            botQuestionOptions: res?.botAnswers?.[0],
            answer: undefined,
          });
          setChatbotData(temp);
        }
        if (!res?.botAnswers?.[0]?.isRecursive) {
          setParentBotAnswerId(res?.botAnswers?.[0]?.id);
        } else {
          setParentBotAnswerId(res?.botAnswers?.[0]?.parentBotAnswer.id);
        }
      }
    }
  };

  function Delete(index) {
    let newArr = [...allChosen];
    newArr.splice(index, 1);
    setAllChosen(newArr);
  }

  function Edit(index) {
    let newArr = [...allChosen];
    newArr[index].flow[newArr[index].flow.length - 1].answer = undefined; //to delete answer of "do you wanna save?"
    setChatbotData(newArr[index].flow);
    Delete(index);
  }



  const HandleOptionClicked = (option) => {
    let temp = [...chatbotData];
    temp[temp.length - 1].answer = option;
    setChatbotData(temp);
    setNextQuestion(nextQuestion + 1);
  };

  function HandlePastOptionClicked(option, index) {
    let temp = [...chatbotData.slice(0, index + 1)];
    temp[temp.length - 1].answer = option;
    if (!temp[index]?.botQuestionOptions?.isRecursive) {
      setParentBotAnswerId(temp[index].botQuestionOptions?.id);
    } else {
      setParentBotAnswerId(temp[index].botQuestionOptions?.parentBotAnswer?.id);
    }
    setChatbotData(temp);
    setNextQuestion(nextQuestion + 1);
  }

  useEffect(() => {
    if (nextQuestion !== 0) {
      if (chatbotData[chatbotData.length - 1]?.botQuestionOptions?.order === -1) {
        // when finished question
        if (chatbotData[chatbotData.length - 1].answer.value === "Evet") {
          chatbotData.pop();
          let temp = [...allChosen];
          temp.push({
            flow: [...chatbotData],
            kpiName: "",
          });
          setAllChosen(temp);
          setChatbotData([]);
          setLoopCounter(loopCounter + 1);
        } else {
          setChatbotData([]);
          setLoopCounter(loopCounter + 1);
        }
      } else {
        GetBotAnswer({
          parentBotAnswerId: parentBotAnswerId,
          includeParents: true,
          includeQuestions: true,
          queryParameters: {
            ParentKpiCategoryId: chatbotData[chatbotData.length - 1]?.answer?.id,
            CompanyId: selectedFactory?.id,
          },
        });
      }
    }
  }, [nextQuestion]);

  const selectedCompanyTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          {option?.image ? <img src={`data:${option?.image?.contentType};base64,${option?.image?.data}`} alt={option?.image?.fileName} /> : <img src={"/images/image.png"} alt={option.name} />}
          <div className="text">{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const companyOptionTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          {option?.image ? <img src={`data:${option?.image?.contentType};base64,${option?.image?.data}`} alt={option?.image?.fileName} /> : <img src={"/images/image.png"} alt={option.name} />}
          <div className="text">{option.name}</div>
        </div>
      );
    }
  };

  function handleKeyPressCategory(event) {
    if (event.key === "Enter" && newCategoryName !== "") {
      const parentKpiCategoryId = chatbotData[chatbotData.length - 2]?.answer.id;
      AddNewCategory(
        {
          name: newCategoryName,
          accountId: userStorage.account.id,
          parentKpiCategoryId: parentKpiCategoryId,
          companyId: selectedCompany?.id,
        },
        lastGetBotAnswerBody.length - 2
      );
      setNewCategoryName("");
    }
  }

  console.log("newAnswer", newAnswerName);

  function handleKeyPressAnswer(event, index) {
    if (event.key === "Enter" && newAnswerName !== "") {
      AddNewAnswer(index);
    }
  }

  const AddNewCategory = async (body, refetchBodyIndex) => {
    const [err, res] = await tryCatch(insertKpiCategory(body));

    console.log("RES", res);

    if (res) {
      GetBotAnswer(lastGetBotAnswerBody[refetchBodyIndex], true); //after added answer to update chatbot
    }

    // fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_CREATE_KPI_CATEGORY,
    //   {
    //     method: "POST",
    //     headers: {
    //       "accept": "application/json",
    //       "Content-Type": "application/json",
    //       "Authorization": "Bearer " + token,
    //     },
    //     body: JSON.stringify(body)
    //   })
    //   .then((res) => {
    //     if (res.ok && res.status === 200) {
    //       return res.json();
    //     }
    //   })
    //   .then(() => {
    //     GetBotAnswer(lastGetBotAnswerBody[refetchBodyIndex], true)//after added answer to update chatbot
    //   })
    //   .catch((err) => console.log(err));
  };

  function AddNewAnswer(index) {
    let parentKpiCategoryId = null;
    if (index > 0) {
      parentKpiCategoryId = chatbotData[index - 1].answer.id;
    }
    AddNewCategory(
      {
        name: newAnswerName,
        accountId: userStorage.account.id,
        parentKpiCategoryId: parentKpiCategoryId,
        companyId: selectedCompany?.id,
      },
      lastGetBotAnswerBody.length - 1
    );
    setNewAnswerName("");
  }

  // const formItemContractName = [
  //   {
  //     label: t("Contract Name"),
  //     name: "contractName",
  //     component: "input",
  //     disable: false,
  //   },
  // ];

  // const formItemDateSection = [
  //   {
  //     label: t("Start Date"),
  //     name: "startDate",
  //     component: "calendar",
  //     type: "date",
  //     maxDate: formik?.values?.endDate && new Date(formik?.values?.endDate),
  //   },
  //   {
  //     label: t("End Date"),
  //     name: "endDate",
  //     component: "calendar",
  //     type: "date",
  //     minDate: formik?.values?.startDate && new Date(formik?.values?.startDate),
  //   },
  // ];

  // const formItemResponsible = [
  //   {
  //     label: "Contract Responsible",
  //     name: "contractResponsible",
  //     component: "dropdown",
  //     options: priority,
  //     optionLabel: "name",
  //     optionValue: "guid",
  //   },
  // ];

  // const formItemPartner = [
  //   {
  //     label: "Partner",
  //     name: "partnerCompanyId",
  //     component: "dropdown",
  //     options: priority,
  //     optionLabel: "name",
  //     optionValue: "guid",
  //   },
  // ];

  // const formItemServiceType = [
  //   {
  //     label: "Service Type",
  //     name: "serviceType",
  //     component: "dropdown",
  //     options: priority,
  //     optionLabel: "name",
  //     optionValue: "guid",
  //   },
  //   {
  //     label: "Activity",
  //     name: "contractResponsible",
  //     component: "dropdown",
  //     options: [
  //       { name: "Active", value: true },
  //       { name: "Passive", value: false },
  //     ],
  //     optionLabel: "name",
  //     optionValue: "value",
  //   },
  // ];

  function HandleChangeKpiName(index, name) {
    let temp = [...allChosen];
    temp[index].kpiName = name;
    setAllChosen(temp);
  }

  const renderBodySla = () => {
    return (
      <>
        <div className="agreement-body">
          <div>
            <div>
              <span className="p-float-label">
                <InputText className="w-full" type="text" id="contractName" placeholder="Contact Name" value={formik.values.contractName} name="contractName" onChange={formik.handleChange} />
                <label htmlFor="contractName">
                  {formik.errors.contractName && formik.touched.contractName && <span className="text-pink-500">*</span>}
                  Contract Name
                </label>
              </span>
              {formik.errors.contractName && formik.touched.contractName && <span className="text-pink-500">{formik.errors.contractName}</span>}
            </div>
            {/* <CustomForm items={formItemContractName} isColumn data={formik} handleChange={formik.handleChange} /> */}
          </div>
          <div className="date-section">
            <div className="date-wrapper">
              <span className="p-float-label">
                <Calendar className="w-full" id="startDate" name="startDate" value={formik.values.startDate} view="month" dateFormat="mm/yy" maxDate={new Date(formik.values.endDate)} onChange={formik.handleChange} />
                <label htmlFor="startDate">
                  {formik.errors.startDate && formik.touched.startDate && <span className="text-pink-500">*</span>}
                  Start Date
                </label>
              </span>
              {formik.errors.startDate && formik.touched.startDate && <span className="text-pink-500">{formik.errors.startDate}</span>}
            </div>
            <div className="date-wrapper">
              <span className="p-float-label">
                <Calendar className="w-full" id="endDate" name="endDate" value={formik.values.endDate} view="month" dateFormat="mm/yy" minDate={new Date(formik.values.startDate)} onChange={formik.handleChange} />
                <label htmlFor="endDate">
                  {formik.errors.endDate && formik.touched.endDate && <span className="text-pink-500">*</span>}
                  End Date
                </label>
              </span>
              {formik.errors.endDate && formik.touched.endDate && <span className="text-pink-500">{formik.errors.endDate}</span>}
            </div>
          </div>
          <div>
            <div>
              <span className="p-float-label">
                <Dropdown name="contractResponsibleId" inputId="contractResponsibleId" options={companyUser?.data || []} value={formik.values.contractResponsibleId} onChange={formik.handleChange} optionLabel="userName" filter optionValue="id" autoComplete="false" ariaLabel="tes" />
                <label htmlFor="contractResponsibleId">
                  {formik.errors.contractResponsibleId && formik.touched.contractResponsibleId && <span className="text-pink-500">*</span>}
                  Contract Responsible
                </label>
              </span>
              {formik.errors.contractResponsibleId && formik.touched.contractResponsibleId && <span className="text-pink-500">{formik.errors.contractResponsibleId}</span>}
            </div>
            {/* <CustomForm items={formItemResponsible} isColumn data={formik} handleChange={formik.handleChange} /> */}
          </div>
          <div>
            <div>
              <span className="p-float-label">
                <Dropdown
                  name="serviceType"
                  inputId="serviceType"
                  options={[
                    { name: "Service Provider", value: "Provider" },
                    { name: "Service Consumer", value: "Consumer" },
                  ]}
                  value={formik.values.serviceType}
                  onChange={formik.handleChange}
                  optionLabel="name"
                  optionValue="value"
                  autoComplete="false"
                  ariaLabel="tes"
                />
                <label htmlFor="serviceType">
                  {formik.errors.serviceType && formik.touched.serviceType && <span className="text-pink-500">*</span>}
                  Service Type
                </label>
              </span>
              {formik.errors.serviceType && formik.touched.serviceType && <span className="text-pink-500">{formik.errors.serviceType}</span>}
            </div>
          </div>
          <div>
            <div>
              <span className="p-float-label">
                <Dropdown
                  name="partnerCompanyId"
                  inputId="partnerCompanyId"
                  options={formik.values.serviceType ? (formik.values.serviceType === "Provider" ? serviceProvider : serviceConsumer) : []}
                  value={formik.values.partnerCompanyId}
                  onChange={formik.handleChange}
                  optionLabel="name"
                  optionValue="id"
                  autoComplete="false"
                  ariaLabel="tes"
                />
                <label htmlFor="partnerCompanyId">
                  {formik.errors.partnerCompanyId && formik.touched.partnerCompanyId && <span className="text-pink-500">*</span>}
                  Partner
                </label>
              </span>
              {formik.errors.partnerCompanyId && formik.touched.partnerCompanyId && <span className="text-pink-500">{formik.errors.partnerCompanyId}</span>}
            </div>
            {/* <CustomForm items={formItemPartner} isColumn data={formik} handleChange={formik.handleChange} /> */}
          </div>
          <div>
            <span className="p-float-label">
              <Dropdown
                name="activity"
                inputId="activity"
                options={[
                  { value: true, name: "Active" },
                  { value: false, name: "Passive" },
                ]}
                value={formik.values.activity}
                onChange={formik.handleChange}
                optionLabel="name"
                optionValue="value"
                autoComplete="false"
                ariaLabel="tes"
              />
              <label htmlFor="activity">
                {formik.errors.activity && formik.touched.activity && <span className="text-pink-500">*</span>}
                Activity
              </label>
            </span>
            {formik.errors.activity && formik.touched.activity && <span className="text-pink-500">{formik.errors.activity}</span>}
            {/* <CustomForm items={formItemServiceType} data={formik} handleChange={formik.handleChange} /> */}
          </div>
        </div>
      </>
    );
  };

  const renderBodyOkr = () => {
    return (
      <>
        <div className="agreement-body">
          <div>
            <div>
              <span className="p-float-label">
                <InputText className="w-full" type="text" id="contractName" placeholder="Contact Name" value={formik.values.contractName} name="contractName" onChange={formik.handleChange} />
                <label htmlFor="contractName">
                  {formik.errors.contractName && formik.touched.contractName && <span className="text-pink-500">*</span>}
                  Contract Name
                </label>
              </span>
              {formik.errors.contractName && formik.touched.contractName && <span className="text-pink-500">{formik.errors.contractName}</span>}
            </div>
            {/* <CustomForm items={formItemContractName} isColumn data={formik} handleChange={formik.handleChange} /> */}
          </div>
          <div className="date-section">
            <div className="date-wrapper">
              <span className="p-float-label">
                <Calendar className="w-full" id="startDate" name="startDate" value={formik.values.startDate} view="month" dateFormat="mm/yy" maxDate={new Date(formik.values.endDate)} onChange={formik.handleChange} />
                <label htmlFor="startDate">
                  {formik.errors.startDate && formik.touched.startDate && <span className="text-pink-500">*</span>}
                  Start Date
                </label>
              </span>
              {formik.errors.startDate && formik.touched.startDate && <span className="text-pink-500">{formik.errors.startDate}</span>}
            </div>
            <div className="date-wrapper">
              <span className="p-float-label">
                <Calendar className="w-full" id="endDate" name="endDate" value={formik.values.endDate} view="month" dateFormat="mm/yy" minDate={new Date(formik.values.startDate)} onChange={formik.handleChange} />
                <label htmlFor="endDate">
                  {formik.errors.endDate && formik.touched.endDate && <span className="text-pink-500">*</span>}
                  End Date
                </label>
              </span>
              {formik.errors.endDate && formik.touched.endDate && <span className="text-pink-500">{formik.errors.endDate}</span>}
            </div>
          </div>
          <div>
            <div>
              <span className="p-float-label">
                <Dropdown name="contractResponsibleId" inputId="contractResponsibleId" options={companyUser?.data || []} value={formik.values.contractResponsibleId} onChange={formik.handleChange} optionLabel="userName" filter optionValue="id" autoComplete="false" ariaLabel="tes" />
                <label htmlFor="contractResponsibleId">
                  {formik.errors.contractResponsibleId && formik.touched.contractResponsibleId && <span className="text-pink-500">*</span>}
                  Contract Responsible
                </label>
              </span>
              {formik.errors.contractResponsibleId && formik.touched.contractResponsibleId && <span className="text-pink-500">{formik.errors.contractResponsibleId}</span>}
            </div>
            {/* <CustomForm items={formItemResponsible} isColumn data={formik} handleChange={formik.handleChange} /> */}
          </div>
          <div className="okr-to-tab-pane">
            <div className={okrTo === "ORGANIZATION" ? "okr-to-type active" : "okr-to-type"} onClick={() => {setOkrTo("ORGANIZATION"); formik.setFieldValue("ownerAccountId", "")}}>
              <label>ORGANIZATION</label>
            </div>
            <div className={okrTo === "USER" ? "okr-to-type active" : "okr-to-type"} onClick={() => {setOkrTo("USER"); formik.setFieldValue("ownerOrganizationId", "")}}>
              <label>USER</label>
            </div>
          </div>
          {okrTo === "ORGANIZATION" ? (
            <>
              <div>
              <div>
              <span className="p-float-label">
                <Dropdown name="ownerOrganizationId" inputId="ownerOrganizationId" options={organizations || []} value={formik.values.ownerOrganizationId} onChange={formik.handleChange} optionLabel="name" filter optionValue="id" autoComplete="false" ariaLabel="tes" />
                <label htmlFor="ownerOrganizationId">
                  {formik.errors.ownerOrganizationId && formik.touched.ownerOrganizationId && <span className="text-pink-500">*</span>}
                 Select Organization
                </label>
              </span>
              {formik.errors.ownerOrganizationId && formik.touched.ownerOrganizationId && <span className="text-pink-500">{formik.errors.ownerOrganizationId}</span>}
            </div>
              </div>
            </>
          ) : (
            <>
              <div>
              <div>
              <span className="p-float-label">
                <Dropdown name="ownerAccountId" inputId="ownerAccountId" options={companyUser?.data || []} value={formik.values.ownerAccountId} onChange={formik.handleChange} optionLabel="userName" filter optionValue="id" autoComplete="false" ariaLabel="tes" />
                <label htmlFor="ownerAccountId">
                  {formik.errors.ownerAccountId && formik.touched.ownerAccountId && <span className="text-pink-500">*</span>}
                  Select User
                </label>
              </span>
              {formik.errors.ownerAccountId && formik.touched.ownerAccountId && <span className="text-pink-500">{formik.errors.ownerAccountId}</span>}
            </div>
              </div>
            </>
          )}
          <div>
            <span className="p-float-label">
              <Dropdown
                name="activity"
                inputId="activity"
                options={[
                  { value: true, name: "Active" },
                  { value: false, name: "Passive" },
                ]}
                value={formik.values.activity}
                onChange={formik.handleChange}
                optionLabel="name"
                optionValue="value"
                autoComplete="false"
                ariaLabel="tes"
              />
              <label htmlFor="activity">
                {formik.errors.activity && formik.touched.activity && <span className="text-pink-500">*</span>}
                Activity
              </label>
            </span>
            {formik.errors.activity && formik.touched.activity && <span className="text-pink-500">{formik.errors.activity}</span>}
            {/* <CustomForm items={formItemServiceType} data={formik} handleChange={formik.handleChange} /> */}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="grid responsive-header2">
      <div className="col-12">
        <div className="flex card navigation-height-clear" style={{ marginBottom: "1rem", padding: "1rem" }}>
          <div className="flex justify-between align-items-center w-full">
            <img className="navigation-main-icon" src={"/images/kpi.png"} alt="logo" width="40" height="40" style={{ marginRight: "1rem" }} />
            <div>
              <div className="text-md navigation-text-md">Add New Contract</div>
              <div className="text-sm navigation-text-sm">Add New Contract with Chatbot</div>
            </div>
            <div className="flex justify-end align-items-center ml-auto">
              <span className="select-company-text">Select Company :</span>
              <Dropdown
                value={selectedCompany}
                onChange={(e) => {
                  setSelectedFactory(e.value);
                  setSelectedCompany(e?.value);
                }}
                options={companies}
                valueTemplate={selectedCompanyTemplate}
                itemTemplate={companyOptionTemplate}
                filter
                filterPlaceholder="Search By Company Name"
                optionLabel="name"
                placeholder="Please Select a Company"
                className="w-full md:w-14rem select-company-dropdown"
              />
            </div>
          </div>
        </div>
        {/* <SearchBar style={{ width: "20vw" }} items={searchItems} handleSearch={(data) => handleSearch(data)} /> */}
        <div className="card datatable empty-main-datatable" style={{ height: "auto" }}>
          {!selectedCompany ? (
            <div className="flex text-center text-md justify-content-center align-items-center" style={{ marginTop: "1rem", color: "#851C1C", height: "700px" }}>
              Please&nbsp;<b>select the company</b>&nbsp; whose list of people you would like to see from the top right or&nbsp; <b>search</b> &nbsp;for it in the search section.
            </div>
          ) : (
            <>
              <div className="create-agreement-wrapper">
                <div className="metadata-field">
                  {confirmClick && (
                    <div className="show-edit-metadata" onClick={() => setConfirmClick(false)}>
                      <img src="/images/metadata-edit.png" alt="edit" />
                    </div>
                  )}
                  <div className="metadata-header">Contract Meta Data</div>
                  <div className="metadata-body" style={{ opacity: confirmClick ? 0.5 : 1, pointerEvents: !confirmClick ? "all" : "none" }}>
                    <div className="metadata-property">
                      <div>
                        <div className="agreement-type-tab-pane">
                          <div title="SLA" className={agreementType === "SLA" ? "agreement-type active" : "agreement-type"} onClick={() => setAgreementType("SLA")}>
                            <label htmlFor="sla">SLA</label>
                            {/* <input type="radio" id="sla" name="sla" defaultValue="SLA" checked={agreementType === "SLA"} /> */}
                          </div>

                          <div title="OLA" className={agreementType === "OLA" ? "agreement-type active" : "agreement-type"} onClick={() => setAgreementType("OLA")}>
                            <label htmlFor="ola">OLA</label>
                            {/* <input type="radio" id="ola" name="ola" defaultValue="OLA" checked={agreementType === "OLA"}  /> */}
                          </div>

                          <div title="UC" className={agreementType === "UC" ? "agreement-type active" : "agreement-type"} onClick={() => setAgreementType("UC")}>
                            <label htmlFor="uc">UC</label>
                            {/* <input type="radio" id="uc" name="uc" defaultValue="UC" checked={agreementType === "UC"}  /> */}
                          </div>

                          <div title="OKR" className={agreementType === "OKR" ? "agreement-type active" : "agreement-type"} onClick={() => setAgreementType("OKR")}>
                            <label htmlFor="okr">OKR</label>
                            {/* <input type="radio" id="okr" name="okr" defaultValue="OKR" checked={agreementType === "OKR"}  /> */}
                          </div>
                        </div>
                      </div>
                      {agreementType === "OKR" ? renderBodyOkr() : renderBodySla()}
                    </div>
                    <div className="metadata-confirm-wrapper">
                      <Button disabled={!(formik.dirty && formik.isValid)} className="confirm-button" label="DONE" onClick={() => setConfirmClick(true)} />
                    </div>
                  </div>
                </div>
                <div className="chatbot-field" style={{ opacity: !confirmClick ? 0.5 : 1, pointerEvents: confirmClick ? "all" : "none" }}>
                  <div className="chatbot-header">
                    <div className="chatbot-side">
                      <div className="chatbot-image-wrapper">
                        <img src="/images/chatbot.png" alt="chatbot" />
                      </div>
                      <div className="chatbot-text-wrapper">
                        <div className="chatbot-name">Experibot</div>
                        <div className="chatbot-status">
                          <span className="chatbot-online"></span> Online
                        </div>
                      </div>
                    </div>
                    <div className="reload-side">
                      <img src="/images/refresh.png" alt="refresh" onClick={() => RefreshChatbot()} />
                    </div>
                  </div>
                  <div className="chatbot-body">
                    {chatbotData &&
                      chatbotData.length > 0 &&
                      chatbotData?.map((item, indexCategory) => (
                        <React.Fragment key={indexCategory}>
                          {item.botQuestionOptions.order === 2 && chatbotData[chatbotData.length - 1].botQuestionOptions.order === 2 && (
                            <div className="new-category-wrapper">
                              <input
                                name={"new-category" + indexCategory}
                                id={"new-category" + indexCategory}
                                type="text"
                                className="input-new-category"
                                value={newAnswerName}
                                onFocus={() => setShowAnswerButtons(true)}
                                onChange={(event) => {
                                  setNewAnswerName(event.target.value);
                                }}
                                onKeyDown={handleKeyPressCategory}
                                placeholder="+ New Category"
                              ></input>
                              {showAnswerButtons && newAnswerName !== "" && (
                                <div className="confirm-buttons">
                                  <div className="confirm" onClick={() => AddNewAnswer(indexCategory)}>
                                    <img src="/images/check-green.png" width={18} height={18} alt="refresh" />
                                  </div>
                                  <div
                                    className="cancel"
                                    onClick={() => {
                                      setShowAnswerButtons(false);
                                      setNewAnswerName("");
                                    }}
                                  >
                                    <img src="/images/x-red.png" width={10} height={10} alt="refresh" />
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="chatbot-message-box" key={indexCategory}>
                            <div className="chatbot-question">
                              <div className="chatbot-image">
                                <img src="/images/chatbot.png" alt="chatbot" />
                              </div>
                              <div className="chatbot-question-text">{item?.botQuestionOptions?.botQuestion?.question}</div>
                            </div>
                            <div className="chatbot-response">
                              {item?.botQuestionOptions?.description?.values?.map((data, index) => (
                                <Button
                                  key={index}
                                  className={item?.answer?.id === data?.id ? "chatbot-question-btn active-question-btn" : "chatbot-question-btn"}
                                  label={data?.value}
                                  onClick={() => {
                                    indexCategory === chatbotData?.length - 1 ? HandleOptionClicked(data) : HandlePastOptionClicked(data, indexCategory);
                                  }}
                                />
                              ))}
                              {indexCategory === chatbotData.length - 1 && (chatbotData[indexCategory].botQuestionOptions.order === 0 || chatbotData[indexCategory].botQuestionOptions.order === 1) && (
                                <div className="new-answer-wrapper">
                                  <input
                                    name={"answer-button-input-" + indexCategory}
                                    id={indexCategory}
                                    type="text"
                                    className="input-add-answer"
                                    value={newAnswerName}
                                    onFocus={() => setShowAnswerButtons(true)}
                                    onChange={(event) => {
                                      setNewAnswerName(event.target.value);
                                    }}
                                    onKeyDown={(e) => handleKeyPressAnswer(e, indexCategory)}
                                    placeholder="+ New Answer"
                                  ></input>
                                  {showAnswerButtons && newAnswerName !== "" && (
                                    <div className="confirm-buttons">
                                      <div className="confirm" onClick={() => AddNewAnswer(indexCategory)}>
                                        <img src="/images/check-green.png" width={18} height={18} alt="refresh" />
                                      </div>
                                      <div
                                        className="cancel"
                                        onClick={() => {
                                          setShowAnswerButtons(false);
                                          setNewAnswerName("");
                                        }}
                                      >
                                        <img src="/images/x-red.png" width={10} height={10} alt="refresh" />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          {item?.answer && (
                            <div className="user-message-box">
                              <div className="user-answer">
                                <div className="user-image">
                                  {userStorage?.account?.image ? (
                                    <img src={`data:${userStorage?.account?.image?.contentType};base64,${userStorage?.account?.image?.data}`} alt="logo" />
                                  ) : (
                                    <i>
                                      <img src="/images/userDefault.png" alt="logo" />
                                    </i>
                                  )}
                                </div>
                                <div className="user-answer-text">{item?.answer?.value}</div>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    <div ref={bottomRef} />
                  </div>
                </div>
                <div className="choice-field" style={{ opacity: !confirmClick ? 0.5 : 1, pointerEvents: confirmClick ? "all" : "none" }}>
                  <div className="choice-header">Your Choices</div>
                  <div className="choice-body">
                    {allChosen &&
                      allChosen.length > 0 &&
                      allChosen?.map((kpi, indexKpi) => (
                        <React.Fragment key={indexKpi}>
                          <div className="choice-wrapper">
                            <div className="kpi-name-wrapper">
                              <input className="kpi-name-input" type="text" placeholder={"KPI Name"} id="kpiName" value={kpi.kpiName} onChange={(event) => HandleChangeKpiName(indexKpi, event.target.value)} autoComplete="on" />
                            </div>
                            {kpi?.flow?.length > 0 &&
                              kpi?.flow?.map((selection, indexSelection) => (
                                <div className="choice-item" key={indexSelection}>
                                  <div className="choice-value">{selection?.answer?.value}</div>
                                  {indexSelection < kpi?.flow?.length - 1 && (
                                    <div className="right-arrow">
                                      <img src="/images/selection-arrow.png" alt="right-arrow" />
                                    </div>
                                  )}
                                </div>
                              ))}
                            <div className="action-button-wrapper">
                              <Button className="edit-btn" label="Edit" onClick={() => Edit(indexKpi)} />
                              <Button className="delete-btn" label="Delete" onClick={() => Delete(indexKpi)} />
                            </div>
                            <div ref={bottomRefChoice} />
                          </div>
                        </React.Fragment>
                      ))}
                    {allChosen?.length > 0 && (
                      <div className="save-choice-btn-wrapper">
                        <Button className="save-choice-btn" label="Confirm" onClick={formik.handleSubmit} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
