import { tryCatch } from "@thalesrc/js-utils";
import { ORGANIZATION_GROUP, GET_ORGANIZATION_GROUP_LIST} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";


export async function insertOrganizationGroup(payload) {
    const url = pathWithQueryString(ORGANIZATION_GROUP)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteOrganizationGroup(payload) {
    const url = pathWithQueryString(ORGANIZATION_GROUP)

    const [error, result] = await tryCatch(http.delete(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getOrganizationGroup(payload) {
    const url = pathWithQueryString(ORGANIZATION_GROUP)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateOrganizationGroup(payload) {
    const url = pathWithQueryString(ORGANIZATION_GROUP)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getOrganizationGroupList(payload) {
    const url = pathWithQueryString(GET_ORGANIZATION_GROUP_LIST, payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data.data;
}

