import { tryCatch } from "@thalesrc/js-utils";
import {
  DELETE_ANNOUNCEMENT,
  DELETE_NEWS,
  GET_ALL_CONTENT_CATEGORY,
  GET_ANNOUNCEMENT_BY_ID,
  GET_ANNOUNCEMENT_FOR_FLASH,
  GET_ANNOUNCEMENT_LIST,
  GET_NEWS_LIST,
  INSERT_ANNOUNCEMENT,
  INSERT_ANNOUNCEMENT_HISTORY,
  INSERT_NEWS,
  INSERT_UPDATE_ANNOUNCEMENT,
  POST_IMG,
  UPDATE_ANNOUNCEMENT,
  UPDATE_NEWS,
} from "../utils/url";
import http from "./http";
import { pathWithQueryString } from "../helpers/pathWithQueryString";

export async function getAnnouncementList(payload) {
  const url = pathWithQueryString(GET_ANNOUNCEMENT_LIST);

  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function getAnnouncementById(payload) {
  const url = pathWithQueryString(GET_ANNOUNCEMENT_BY_ID, payload);

  const [error, result] = await tryCatch(http.get(url, payload));

  if (error) throw error;

  return result.data.data;
}
export async function getAnnouncementForFlash(payload) {
  const url = pathWithQueryString(GET_ANNOUNCEMENT_FOR_FLASH, payload);

  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data.data;
}

export async function getAllContentCategory(payload) {
  const url = pathWithQueryString(GET_ALL_CONTENT_CATEGORY, payload);

  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data.data;
}

export async function insertAnnouncement(payload) {
  const [error, result] = await tryCatch(http.post(INSERT_ANNOUNCEMENT, payload));

  if (error) throw error;

  return result.data.data;
}

export async function UpdateAnnouncement(payload) {
  const [error, result] = await tryCatch(http.put(UPDATE_ANNOUNCEMENT, payload));

  if (error) throw error;

  return result.data.data;
}

export async function deleteAnnouncement(payload) {
  const [error, result] = await tryCatch(http.put(DELETE_ANNOUNCEMENT, payload));

  if (error) throw error;

  return result.data.data;
}

export async function postImg(payload) {
  const [error, result] = await tryCatch(
    http.post(`${POST_IMG}?fileName=${payload.PostImage}&verticalName=${payload.verticalName}`, payload.file, {
      headers: {
        "Content-Type": "multipart/form-data",
        tenantId: JSON.parse(localStorage?.getItem("selectedFactory")) ? JSON.parse(localStorage?.getItem("selectedFactory"))?.label : 0,
      },
    })
  );

  if (error) throw error;

  return result.data.data;
}

export async function insertAnnouncementHistory(payload) {
  const url = pathWithQueryString(INSERT_ANNOUNCEMENT_HISTORY, payload);

  const [error, result] = await tryCatch(http.post(url));

  if (error) throw error;

  return result.data.data;
}
