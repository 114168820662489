import { tryCatch } from "@thalesrc/js-utils";
import { 
INSERT_SLA,
DELETE_SLA,
GET_ALL_SLA_LIST,
GET_ALL_SLA_LIST_BY_ACCOUNT_ID,
GET_ALL_SLA_LIST_BY_ACCOUNT_ID_AND_COMPANY_ID,
GET_ALL_SLA_LIST_BY_COMPANY_ID_AND_COMPANY_GROUP_ID,
GET_KPI_AND_TARGET,
GET_SLA_BY_ID,
GET_SLA_BY_KIND,
GET_SLA_BY_OKR,
GET_SLA_STATISTICS_BY_START_AND_FINISH_DATE,
GET_TARGET_STATISTICS,
PURGE_SLA,
UPDATE_SLA,
GET_SLA_ORGANIZATIONS
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertSla(payload) {
    const url = pathWithQueryString(INSERT_SLA)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function purgeSla(payload) {
    const url = pathWithQueryString(PURGE_SLA)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateSla(payload) {
    const url = pathWithQueryString(UPDATE_SLA)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteSla(payload) {
    const url = pathWithQueryString(DELETE_SLA)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAllSlaList(payload) {
    const url = pathWithQueryString(GET_ALL_SLA_LIST, payload)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data;
}

export async function getAllSlaListByAccountId(payload) {
    const url = pathWithQueryString(GET_ALL_SLA_LIST_BY_ACCOUNT_ID)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAllSlaListByAccountIdAndCompanyId(payload) {
    const url = pathWithQueryString(GET_ALL_SLA_LIST_BY_ACCOUNT_ID_AND_COMPANY_ID)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAllSlaListByCompanyIdAndCompanyGroupId(payload) {
    const url = pathWithQueryString(GET_ALL_SLA_LIST_BY_COMPANY_ID_AND_COMPANY_GROUP_ID, payload)

    const [error, result] = await tryCatch(http.get(url, payload));
    
    if (error) throw error;
  
    return result.data;
}

export async function getKpiAndTarget(payload) {
    const url = pathWithQueryString(GET_KPI_AND_TARGET, payload)

    const [error, result] = await tryCatch(http.get(url, payload));
    
    if (error) throw error;
  
    return result.data.calculationResults;
}

export async function getSlaById(payload) {
    const url = pathWithQueryString(GET_SLA_BY_ID, payload)

    const [error, result] = await tryCatch(http.get(url, payload));
    
    if (error) throw error;
  
    return result.data.data;
}

export async function getSlaByKind(payload) {
    const url = pathWithQueryString(GET_SLA_BY_KIND)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getSlaByOkr(payload) {
    const url = pathWithQueryString(GET_SLA_BY_OKR)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getSlaStatisticsByStartAndFinishDate(payload) {
    const url = pathWithQueryString(GET_SLA_STATISTICS_BY_START_AND_FINISH_DATE,payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data;
}

export async function getTargetStatistics(payload) {
    const url = pathWithQueryString(GET_TARGET_STATISTICS)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getSlaListForOrganization(payload) {
    const url = pathWithQueryString(GET_SLA_ORGANIZATIONS,payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data.data;
}