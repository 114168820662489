import { useContext } from "react";
import { ToasterContext } from "../context/ToasterContext";


/**
 *
 * @param {string} urlString
 * @param {Object} params
 * @param {'value'} params.key
 * @example getUrlWithParams('/address/streets', { cityCode: '1', districtCode: '4' })
 * @returns {String}
 */
export function ImageSizeControllerObject(data, openToaster) {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (data.size > maxSizeInBytes) {
          openToaster("bottom-right", {
            severity: "error",
            summary: data.name,
            detail: "Dosya Boyutunu aşmaktadır",
            life: 3000
      });
      return null;
    }
  
    return 0; // Replace with the desired return value
  }