import { tryCatch } from "@thalesrc/js-utils";
import { COMPANY_CORDLYNX, GET_COMPANY_LIST, INSERT_CONTACT_TO_COMPANY } from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function getCompanyList(payload) {
  const url = pathWithQueryString(GET_COMPANY_LIST, payload);

  const [error, result] = await tryCatch(http.get(url,{
    "Accept": "application/json",
    // "Authorization": "Bearer " + token,
    'x-tenant': 'test'
  }));

  if (error) throw error;

  return result.data.data;
}

export async function insertCompany(payload) {
  const url = pathWithQueryString(COMPANY_CORDLYNX);

    const [error, result] = await tryCatch(http.post(url, payload,{
      headers: {
          'Content-Type': 'multipart/form-data',
        },
    }));

    if (error) throw error;

    return result.data.data;
}

export async function insertContactToCompany(payload) {
  const url = pathWithQueryString(INSERT_CONTACT_TO_COMPANY);

    const [error, result] = await tryCatch(http.post(url, payload,{
      headers: {
          'Content-Type': 'multipart/form-data',
        },
    }));

    if (error) throw error;

    return result.data.data;
}


export async function deleteCompany(payload) {
  const url = pathWithQueryString(COMPANY_CORDLYNX + payload);

  const [error, result] = await tryCatch(http.delete(url, payload));

  if (error) throw error;
  return result.data.data;
}

export async function getCompany(payload,id) {
  const url = pathWithQueryString(COMPANY_CORDLYNX + id, payload);

  const [error, result] = await tryCatch(http.get(url));
  
  if (error) throw error;

  return result.data.data;
}

export async function updateCompany(payload, id) {
  const url = pathWithQueryString(COMPANY_CORDLYNX + id);
  
  const [error, result] = await tryCatch(http.put(url, payload,{
    headers: {
        'Content-Type': 'multipart/form-data',
      },
  }));

  if (error) throw error;

  return result.data.data;
}
