import { tryCatch } from "@thalesrc/js-utils";
import { COMPANY_GROUP, INSERT_COMPANY_TO_COMPANY_GROUP, GET_COMPANY_GROUP_LIST } from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function getCompanyGroupList(payload) {
  const url = pathWithQueryString(GET_COMPANY_GROUP_LIST, payload);

  const [error, result] = await tryCatch(http.get(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function getCompanyGroupListTable(payload) {
  const url = pathWithQueryString(GET_COMPANY_GROUP_LIST, payload);

  const [error, result] = await tryCatch(http.get(url, payload));

  if (error) throw error;

  return result.data;
}

export async function insertCompanyGroup(payload) {
  const url = pathWithQueryString(COMPANY_GROUP);
  
  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function deleteCompanyGroup(payload) {
  const url = pathWithQueryString(COMPANY_GROUP);

  const [error, result] = await tryCatch(http.delete(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function getCompanyGroup(payload) {
  const url = pathWithQueryString(COMPANY_GROUP);

  const [error, result] = await tryCatch(http.get(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function updateCompanyGroup(payload) {
  const url = pathWithQueryString(COMPANY_GROUP);

  const [error, result] = await tryCatch(http.put(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function insertCompanyToCompanyGroup(payload) {
  const url = pathWithQueryString(INSERT_COMPANY_TO_COMPANY_GROUP);

  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function removeCompanyFromCompanyGroup(payload) {
  const url = pathWithQueryString(COMPANY_GROUP);

  const [error, result] = await tryCatch(http.delete(url, payload));

  if (error) throw error;

  return result.data.data;
}
