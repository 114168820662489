import { tryCatch } from "@thalesrc/js-utils";
import { ACCOUNT, GET_ACCOUNT_LIST,ADD_POSITION_TO_ACCOUNT, ACCOUNT_REQUEST } from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function getAccountList(payload) {
  
  const url = pathWithQueryString(GET_ACCOUNT_LIST,payload);

  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data;
}

export async function removePositionFromAccount(payload) {
  const url = pathWithQueryString(ACCOUNT+`position=${payload.position}&account=${payload.account}`);

  const [error, result] = await tryCatch(http.delete(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function addPositionToAccount(payload) {
  const url = pathWithQueryString(ADD_POSITION_TO_ACCOUNT);
  
  const [error, result] = await tryCatch(http.post(url, payload));

  if (error) throw error;

  return result.data.data;
}

export async function deleteAccount(payload) {
  const url = pathWithQueryString(ACCOUNT + payload);

  const [error, result] = await tryCatch(http.delete(url));

  if (error) throw error;

  return result.data.data;
}

export async function getAccount(payload,id) {
  const url = pathWithQueryString(ACCOUNT + id, payload);
  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data.data;
}

export async function updateAccount(payload, id) {

  const url = pathWithQueryString(ACCOUNT + id);

  const [error, result] = await tryCatch(http.put(url, payload, {
    headers: {
        'Content-Type': 'multipart/form-data',
      },
  }));


  if (error) throw error;

  return result.data.data;
}
