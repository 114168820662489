import React, { useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import styled from "styled-components";
import media from "../utils/media";
import classNames from "classnames";
import { t } from "i18next";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    min-width: ${(p) => p.minWidth};

    ${media.xs`
      min-width: 100%;
    `}
  }
  .p-float-label {
    width: 100%;
  }

  ${({ mode }) =>
    mode === "basic" &&
    `
    .p-float-label, .p-fileupload-choose, .p-fileupload-basic{
    height: 100px;
    width: 100px;
    background: transparent;
    color: black;
    text-align:center;
    background-color: #88A9DA;
    justify-content: center;
    border-radius: 50%;

    .p-button-label{
      flex: initial;
    }
  }
  .p-fileupload-basic {
    position: absolute;
    top:0;
    left:0;
  }
  .p-fileupload .p-fileupload-content {
  display: none;
}
  .p-fileupload-choose-selected{
    span{
      display: none !important;
    }
  }

  .p-fileupload-choose-selected:hover{
    span{
      display: block !important;
    }
  }
  `}
`;

function CustomFileUpload2({ mode = "basic", errorMsg, accept, disabled, url, className, containerClassName, onChange, value, name, label, required = false, minWidth = "250px", emptyTemplate }) {
  const [imgSrc, setimgSrc] = useState("");
  const toast = useRef(null);
  const fileUploadRef = useRef(null);
  const [files, setFiles] = useState([]);
  const onBasicUpload = () => {
    toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded with Basic Mode" });
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
        {chooseButton}
      </div>
    );
  };

  const handleClear = () => {
    setimgSrc("");
  };
  const handleChange = (e) => {
    onChange(e.originalEvent.target.files[0]);
    setimgSrc(e.files[0].objectURL);

    const fileNames = [...e.files].filter((file) => !files.map((a) => a.name).includes(file.name));
    setFiles((prev) => [...prev, ...fileNames]);
  };
  const handleRemove = (event) => {
    onChange([]);
  };

  return (
    <Container minWidth={minWidth} className={containerClassName} isImg={imgSrc ? true : false} mode={mode}>
      <span className="p-float-label">
        <FileUpload
          mode={mode}
          ref={fileUploadRef}
          onRemove={(e) => {
            handleRemove(e);
          }}
          disabled={disabled}
          id={name}
          value={value}
          name={name}
          onSelect={(e) => handleChange(e)}
          autoComplete="off"
          onUpload={onBasicUpload}
          onBeforeSend={() => {}}
          headerTemplate={headerTemplate}
          emptyTemplate={emptyTemplate}
          className={classNames(className, { "p-invalid": errorMsg })}
          onClear={handleClear}
          accept={accept}
        />
           {/* {url && (
          <div>
            <h5>{label}</h5>
            <img src={`data:${url?.contentType};base64,${url?.data}`} alt="logo" width="80" height="80" style={{ marginRight: "1rem", borderRadius: '50%' }} />
          </div>
        )} */}

        <label htmlFor={name}>
          <div style={{ display: "block", height: "40px", margin: "20px", fontSize: "20px" }}>{errorMsg && errorMsg}</div>
        </label>
      </span>
    </Container>
  );
}

export default CustomFileUpload2;
