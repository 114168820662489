import { tryCatch } from "@thalesrc/js-utils";
import { 
INSERT_SLA_TYPE,
DELETE_SLA_TYPE,
GET_ALL_SLA_TYPE_LIST,
GET_SLA_TYPE,
UPDATE_SLA_TYPE
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertSlaType(payload) {
    const url = pathWithQueryString(INSERT_SLA_TYPE)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteSlaType(payload) {
    const url = pathWithQueryString(DELETE_SLA_TYPE)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAllSlaTypeList(payload) {
    const url = pathWithQueryString(GET_ALL_SLA_TYPE_LIST)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.slaTypes;
}

export async function updateSlaType(payload) {
    const url = pathWithQueryString(UPDATE_SLA_TYPE)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getSlaType(payload) {
    const url = pathWithQueryString(GET_SLA_TYPE)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}