import { tryCatch } from "@thalesrc/js-utils";
import { ORGANIZATION, GET_ORGANIZATION_LIST, ORGANIZATION_POSITION, GET_POSITION_LIST} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";


export async function insertOrganization(payload) {
    const url = pathWithQueryString(ORGANIZATION)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteOrganization(payload) {
    const url = pathWithQueryString(ORGANIZATION)

    const [error, result] = await tryCatch(http.delete(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getOrganization(payload) {
    const url = pathWithQueryString(ORGANIZATION)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateOrganization(payload) {
    const url = pathWithQueryString(ORGANIZATION)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function insertPositionToOrganization(payload) {

    console.log(payload)

    const [error, result] = await tryCatch(http.post(ORGANIZATION_POSITION, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deletePositionFromOrganization(payload) {

    const [error, result] = await tryCatch(http.delete(ORGANIZATION_POSITION  + "/" + `${payload?.rowData}`));

    if (error) throw error;
  
    return result.data.data;
}

export async function updatePosition(payload) {
    const url = pathWithQueryString(ORGANIZATION_POSITION)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getOrganizationList(payload) {
    const url = pathWithQueryString(GET_ORGANIZATION_LIST,payload)

    const [error, result] = await tryCatch(http.get(url ));

    if (error) throw error;
  
    return result.data;
}

export async function getPositionList(payload) {
    const url = pathWithQueryString(GET_POSITION_LIST, payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data.data;
}