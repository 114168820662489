import { tryCatch } from "@thalesrc/js-utils";
import { 
    INSERT_KPI_CATEGORY,
    DELETE_KPI_CATEGORY,
    GET_ALL_KPI_CATEGORY_LIST,
    GET_KPI_CATEGORY_BY_ID,
    PURGE_DELETE_KPI_CATEGORY,
    UPDATE_KPI_CATEGORY
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertKpiCategory(payload) {
    const url = pathWithQueryString(INSERT_KPI_CATEGORY)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteKpiCategory(payload) {
    const url = pathWithQueryString(DELETE_KPI_CATEGORY)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getAllKpiCategoryList(payload) {
    const url = pathWithQueryString(GET_ALL_KPI_CATEGORY_LIST, payload)

    const [error, result] = await tryCatch(http.get(url, payload));
    
    if (error) throw error;
  
    return result.data;
}

export async function getKpiByCategoryId(payload) {
    const url = pathWithQueryString(GET_KPI_CATEGORY_BY_ID)

    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function purgeDeleteKpiCategory(payload) {
    const url = pathWithQueryString(PURGE_DELETE_KPI_CATEGORY)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateKpiCategory(payload) {
    const url = pathWithQueryString(UPDATE_KPI_CATEGORY)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}
