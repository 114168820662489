/* eslint-disable react-hooks/exhaustive-deps */
import { tryCatch } from "@thalesrc/js-utils";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import CustomForm from "../../components/CustomForm";
import PageActions from "../../components/PageActions";
import { LoadingContext } from "../../context/Loading";
import { ToasterContext } from "../../context/ToasterContext";
import { useFetch } from "../../hooks/use-fetch";
import { Card } from "primereact/card";
import { UpdateAnnouncement, getAllContentCategory, getAnnouncementById, insertAnnouncement, postImg } from "../../service/announcement";
import { isValidURL } from "../../utils/validUrl";
import { ToasterHelper } from "../../helpers/toaster";
import { ImageSizeController } from "../../helpers/imageSizeController";
import { ImageSizeControllerObject } from "../../helpers/imageSizeControllerObject";
import { dateConverter } from "../../helpers/dateConverter";
import { getAccountGroupList } from "../../service/cordlynxServices/permission";
import { GetRoles } from "../../utils/roleFormat";
import { Button } from "primereact/button";
import { addPositionToAccount, getAccount, removePositionFromAccount, updateAccount } from "../../service/cordlynxServices/account";
import { getPositionList } from "../../service/cordlynxServices/organization";
import { getOrganizationGroupList } from "../../service/cordlynxServices/organizationGroup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ActionButtons from "../../components/ActionButtons";
import { Authority } from "../../utils/authority";
import { Paginator } from "primereact/paginator";
import { getKpiAndTarget, getSlaById, updateSla } from "../../service/cordlynxServices/sla";
import { getAllSlaTypeList } from "../../service/cordlynxServices/slaType";
import { InputText } from "primereact/inputtext";
import { useDialogState } from "../../hooks/use-dialog-state";
import { getAllKpiAndDetailsList, getAllKpiByCompanyGroupId, getAllKpiByCompanyId, getKpiById, updateKpiById } from "../../service/cordlynxServices/kpi";
import { UserContext } from "../../context/UserContext";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { formatDateTR, formatDateTRLong } from "../../utils/dateFormatTR";
import { getAllPriorityList } from "../../service/cordlynxServices/priority";

import { getCompany } from "../../service/cordlynxServices/company";
import pageURL from "../../utils/pageUrls";
import { Tooltip } from "primereact/tooltip";
import { AutoComplete } from "primereact/autocomplete";
import { bindKpiToAgreement, signAgreements } from "../../service/cordlynxServices/agreement";
import { getMailTemplateList, sendMail } from "../../service/cordlynxServices/mail";
import { Checkbox } from "primereact/checkbox";
import { use } from "i18next";
import { deleteVerifiedSla } from "../../service/cordlynxServices/verifiedSla";
import { getAllKpiCategoryList } from "../../service/cordlynxServices/kpiCategory";

const SocialManagementEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { mode } = useParams();
  const [first, setFirst] = useState(0);
  const { setLoading, showLoading, hideLoading } = useContext(LoadingContext);
  const { userStorage } = useContext(UserContext);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [kpiFilterValue, setKpiFilterValue] = useState("");
  const [slaFilterValue, setSlaFilterValue] = useState("");
  const [opened, open, close] = useDialogState();
  const { openToaster } = useContext(ToasterContext);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState();
  const [documentVisible, setDocumentVisible] = useState(false);
  const [serviceVisible, setServiceVisible] = useState(false);
  const [slaPaginate, setSlaPaginate] = useState([]);
  const [showSignAgreement, setShowSignAgreement] = useState(false);
  const [mailList, setMailList] = useState([]);
  const [mailSearchInputValue, setMailSearchInputValue] = useState("");
  const [companyAllUser, setCompanyAllUser] = useState([]);
  const [filteredCompanyUser, setFilteredCompanyUser] = useState([]);
  const [selectedMailList, setSelectedMailList] = useState([]);
  const [payloadCategory, setpayloadCategory] = useState({
    deleted: false,
    take: 1000,
    skip: 0,
  });
  const [payloadPriority, setPayloadPriority] = useState({
    deleted: false,
    take: 999,
    skip: 0,
  });
  const [kpiFilters, setKpiFilters] = useState({
    name: { value: null },
  });

  const [slaFilters, setSlaFilters] = useState({
    name: { value: null },
    key: { value: null },
  });


  const [payloadKpi, setPayloadKpi] = useState({
    deleted: false,
    id: id,
  });

  const [kpiData, reloadKpiData] = useFetch(() => getKpiById(payloadKpi), [], { setLoading, reloadDeps: [payloadKpi], deps: [payloadKpi] });

  const [priority, reloadPriority] = useFetch(() => getAllPriorityList(payloadPriority), [], { setLoading, reloadDeps: [payloadPriority], deps: [payloadPriority] });
  const [companyData, reloadCompanyData] = useFetch(() => getCompany({ includes: "Image&includes=Accounts" }, kpiData?.companyId), [], { setLoading, reloadDeps: [kpiData], deps: [kpiData] });
  const [kpiCategory, reloadKpiCategory] = useFetch(() => getAllKpiCategoryList(payloadCategory), [], { setLoading, reloadDeps: [payloadCategory], deps: [payloadCategory] });

console.log("KPUIDATA",kpiData)
console.log("KPICATEGORY",kpiCategory)

/* eslint-disable no-unused-expressions */
useEffect(() => {
    slaPaginate?.forEach((rowData) => {
      partnerCompanyBodyTemplate(rowData);
    });
  }, [slaPaginate]);
/* eslint-disable no-unused-expressions */

  const [initialValues, setInitialValues] = useState({
    id: "",
    name: "",
    categoryId: "",
    priorityId: "",
    kpiResponsible: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(t("validation.required")),
    categoryId: Yup.string().required(t("validation.required")),
    priorityId: Yup.string().required(t("validation.required")),
  });


  const btnItems = [{ label: t("general.goBack", "Back"), onClick: () => history.goBack(), className: "p-button-raised p-button-text new-back-button" }];

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (data) => {
      showLoading();

      const sendObj = {
        id: data.id,
        name: data.name,
        priorityId: data.priorityId,
        accountId: userStorage?.account?.id,
        kpiCategoryId: data.categoryId,
        companyId: kpiData?.companyId,
      };

      const [err, res] = await tryCatch(updateKpiById(sendObj));

      if (err) {
        hideLoading();
        openToaster("bottom-right", { severity: "error", summary: "Failed", detail: "An unexpected error occurred", life: 3000 });
        return;
      }

      if (res) {
        openToaster("bottom-right", { severity: "success", summary: "Success", detail: "The KPI has been successfully updated", life: 3000 });
        hideLoading();
        reloadKpiData();
        // history.goBack();
      }
    },
  });

//   useEffect(() => {
//     const pageDataSla = kpiData?.agreements?.slice(0, 9);
//     setSlaPaginate(pageDataSla);
//   }, [kpiData]);

  useEffect(() => {
    if (globalFilterValue) {
      const filteredAgreements = kpiData?.agreements?.filter((agreement) =>
        agreement.name.toLowerCase().includes(globalFilterValue.toLowerCase())
      )?.slice(0, 9);
      setSlaPaginate(filteredAgreements);
    } else {
      setSlaPaginate(kpiData?.agreements)?.slice(0, 9);
    }
  }, [globalFilterValue, kpiData]);


  useEffect(() => {
    setInitialValues({
    id: kpiData?.id,
      name: kpiData?.name,
      categoryId: kpiData?.kpiCategory?.id,
      priorityId: kpiData?.priority?.id,
    });
  }, [kpiData]);

  btnItems.push({
    label: t("general.save", "Save Changes"),
    className: "new-save-button",
    type: "submit",
    disabled: !formik.dirty,
    onClick: () => {
      setButtonDisabled(true);
      formik.handleSubmit();
      setTimeout(() => {
        setButtonDisabled(false);
      }, 3000);
    },
  });

  const formItemsKpi = [
    {
      label: t("KPI Name"),
      name: "name",
      component: "input",
      disabled: false,
    },
    {
      label: "KPI Set",
      name: "categoryId",
      component: "dropdown",
      options: kpiCategory,
      optionLabel: "name",
      optionValue: "guid",
    },
    {
      label: "Priority",
      name: "priorityId",
      component: "dropdown",
      options: priority,
      optionLabel: "name",
      optionValue: "guid",
    },
  ];

console.log("PRIORITY", priority)

  const headerMetadataSetting = (
    <div className="p-datatable-header3">
      <div className="align-items-center">KPI's Metadata Settings</div>
    </div>
  );

  const onPageChange = (event) => {
    setFirst(event.first);

    const startIndex = event.page * event.rows;
    const endIndex = startIndex + event.rows;

    const pageData = kpiData?.agreements?.slice(startIndex, endIndex);
    setSlaPaginate(pageData);
  };


  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <div className="flex align-items-center ">
          <div className="navigation-company-header">Related SLA</div>
        </div>
        <div className="flex align-content-center ">
          <InputText value={globalFilterValue} style={{ padding: "0 0 0 1rem", height: "40px", width: "300px", background: "white" }} onChange={(e) => setGlobalFilterValue(e.target.value)} placeholder="Search By SLA Name" />
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const [partnerCompanies, setPartnerCompanies] = useState({});
  const partnerCompanyBodyTemplate = async (rowData) => {
    const [error, result] = await tryCatch(
      getCompany( {includes:"Accounts"},rowData?.partnerCompanyId)
    );

    const companyName = result?.name
    setPartnerCompanies((prevCompanies) => ({ ...prevCompanies, [rowData.id]: { name: companyName} }));
  };

  return (
    <div className="grid responsive-header">
      <div className="col-12">
        <div className="flex card navigation-height-clear" style={{ padding: "1rem" }}>
          <div className="flex justify-between align-items-center w-full">
            {companyData?.image ? (
              <img className="navigation-company-logo" src={`data:${companyData?.image?.contentType};base64,${companyData?.image?.data}`} alt="logo" />
            ) : (
              //Default company logo gelecek
              <img className="navigation-company-logo" src={"/images/userDefault.png"} alt="logo" />
            )}
            <div>
              <div className="text-md navigation-text-md">{kpiData?.name}</div>
              <div className="text-sm navigation-text-sm">Customers / Contracts / {kpiData?.name}</div>
            </div>
            <div className="flex justify-end ml-auto">
              <PageActions items={btnItems} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="information-data7">
          <Card className="metadata-width-adjust-kpi" header={headerMetadataSetting}>
            <CustomForm items={formItemsKpi} data={formik} handleChange={formik.handleChange} mode={mode} />
          </Card>
        </div>
      </div>
      <div className="col-12">
        <div className="card datatable datatable-padding3">
          <>
            <DataTable className="user-padding-left list-component-height responsive-user" header={header} value={slaPaginate} responsiveLayout="scroll" emptyMessage={t(" ")}>
              <Column field="name" header={t("SLA Name")} />
              <Column field="partnerCompany" header={t("Customer")} body={(rowData) => {
                 const companyName = partnerCompanies[rowData.id]?.name;
                 return companyName || "-";
              }}  />
              <Column field="status" header={t("Status")} body={(rowData) => rowData?.isActive ? "Active" : "Passive"} />
              <Column field="startDate" header={t("Start Date")} body={(rowData) => formatDateTRLong(new Date(rowData?.startDate))} />
              <Column field="endDate" header={t("End Date")} body={(rowData) => formatDateTRLong(new Date(rowData?.startDate))} />
              <Column
                header={t("Actions")}
                headerStyle={{ width: "4rem", textAlign: "center" }}
                bodyStyle={{ textAlign: "center", overflow: "visible" }}
                body={(rowData) => {
                  return (
                    <div className="flex align-content-center justify-content-center">
                      <i className="cursor-pointer mr-4" code={Authority.KpiRead} type="button" label={t("general.detail")} onClick={() => history.push(`${pageURL.advertisementManagement}/edit/${rowData?.id}`)}>
                        <img className="actions-icons-new external-tooltip" src="/images/external-link-icon.svg" data-pr-tooltip="SLA Detail" data-pr-position="top" />
                        <Tooltip target=".external-tooltip" />
                      </i>
                    </div>
                  );
                }}
              />
            </DataTable>
            <div className="content-bottom2">
              <Paginator className="nusret-paginate" first={first} rows={9} totalRecords={kpiData?.agreements?.length} onPageChange={(e) => onPageChange(e)} />
              <div className="flex justify-content-end" style={{ backgroundColor: "white" }}>
                <div className="mr-2 total-value">Total SLA : {kpiData?.agreements?.length}</div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default SocialManagementEdit;
