import { tryCatch } from "@thalesrc/js-utils";
import { 
    INSERT_BOT_ANSWER,
    DELETE_BOT_ANSWER,
    GET_BOT_ANSWER_BY_ID,
    UPDATE_BOT_ANSWER
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertBotAnswer(payload) {
    const url = pathWithQueryString(INSERT_BOT_ANSWER)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteBotAnswer(payload) {
    const url = pathWithQueryString(DELETE_BOT_ANSWER)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getBotAnswerById(payload) {
    const url = pathWithQueryString(GET_BOT_ANSWER_BY_ID)

    const [error, result] = await tryCatch(http.post(url, payload));
    console.log("result"    , result)
    if (error) throw error;
  
    return result.data;
}

export async function updateBotAnswer(payload) {
    const url = pathWithQueryString(UPDATE_BOT_ANSWER)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}