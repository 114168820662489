import { tryCatch } from "@thalesrc/js-utils";
import { SEND_MAIL, MAIL_TEMPLATE, GET_MAIL_TEMPLATE_LIST} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function sendMail(payload) {
    const url = pathWithQueryString(SEND_MAIL);
    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function insertMailTemplate(payload) {
    const url = pathWithQueryString(MAIL_TEMPLATE);
    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateMailTemplate(payload) {
    const url = pathWithQueryString(MAIL_TEMPLATE);
    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function getMailTemplateList(payload) {
    const url = pathWithQueryString(GET_MAIL_TEMPLATE_LIST);
    const [error, result] = await tryCatch(http.get(url, payload));

    if (error) throw error;
  
    return result.data.data;
}
