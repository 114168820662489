const BASE_URL = process.env.REACT_APP_BASE_URL;
const SLM_URL = process.env.REACT_APP_SLM_URL;

// const BASE_URL = "http://10.1.23.203:5005/api";
// const SLM_URL = "http://10.1.23.203:5263/api";

export const LOGIN_URL = `${BASE_URL}/connect/token`;
export const LOGOUT_URL = `${BASE_URL}/user/logout`;
export const LOGIN_URL_CODE = `${BASE_URL}/auth/Login`;
export const LOGIN_URL_PHONE = `${BASE_URL}/auth/PhoneVerify`;
export const CHANGE_USER_PERMISSION = `${BASE_URL}/user/ChangeUserPermission`;

export const GENERATE_PASSWORD = `${BASE_URL}/user/generate-password-for-register`;
export const FORGET_PASSWORD = `${BASE_URL}/user/resetpassword`;
export const GET_CUSTOMER_URL = `${BASE_URL}/user/GetUserInitialize`;
export const GENERATE_PASSWORD_FORGET = `${BASE_URL}/user/generate-password-for-reset`;
export const CHANGE_PASSWORD = `${BASE_URL}/user/passwordchange`;

//user
export const GET_USERS = `${BASE_URL}/user/GetAllUser`;
export const GET_USER_BY_ID = `${BASE_URL}/user/GetById`;
export const GET_USERS_FOR_DROPDOWN = `${BASE_URL}/user/`;
export const GET_WORKINGTYPES = `${BASE_URL}/user/dropdown-workingtypes`;
export const GET_ENGLISHLEVELS = `${BASE_URL}/user/dropdown-englishlevels`;
export const GET_USERTYPES = `${BASE_URL}/user/dropdown-usertypes`;
export const GET_PERSONALTYPES = `${BASE_URL}/user/dropdown-personaltypes`;
export const GET_WORKINGADRESS = `${BASE_URL}/user/dropdown-workingAdress`;
export const GET_CITY_FOR_DROPDOWN = `${BASE_URL}/user/get-city-for-dropdown`;
export const SESSION_CHECK = `${BASE_URL}/user/CheckSession`;

//announcement
export const GET_ANNOUNCEMENT_LIST = `${BASE_URL}/EventAnnouncement/GetAll`;
export const GET_ANNOUNCEMENT_BY_ID = `${BASE_URL}/EventAnnouncement/GetById`;
export const GET_ANNOUNCEMENT_FOR_FLASH = `${BASE_URL}/EventAnnouncement/GetEventAnnouncementForFlash?userId=2`;
export const INSERT_ANNOUNCEMENT_HISTORY = `${BASE_URL}/eventAnnouncement/InsertEventAnnouncementHistory`;
export const INSERT_ANNOUNCEMENT = `${BASE_URL}/EventAnnouncement/Insert`;
export const UPDATE_ANNOUNCEMENT = `${BASE_URL}/EventAnnouncement/Update`;
export const DELETE_ANNOUNCEMENT = `${BASE_URL}/EventAnnouncement/Delete`;

//woman
export const GET_WOMAN_LIST = `${BASE_URL}/EventAnnouncement/GetAllWowanClub`;
export const GET_WOMAN_BY_ID = `${BASE_URL}/EventAnnouncement/GetById`;
export const INSERT_WOMAN = `${BASE_URL}/EventAnnouncement/InsertWowanClub`;
export const UPDATE_WOMAN = `${BASE_URL}/EventAnnouncement/UpdateClubUpdate`;
export const DELETE_WOMAN = `${BASE_URL}/EventAnnouncement/DeleteWomanClub`;

//feedback
export const GET_ALL_FEEDBACK = `${BASE_URL}/feedback/GetFeedbackByTenantIdForWeb`;
export const GET_FEEDBACK_BY_ID = `${BASE_URL}/feedback/GetById`;
export const GET_FEEDBACK_EXCEL = `${BASE_URL}/feedback/ExportExcel`;
export const GET_FEEDBACK_BY_ID_FOR_SEEN = `${BASE_URL}/EventAnnouncementHistory/GetHasSeenReportById`;

//news
export const GET_NEWS_LIST = `${BASE_URL}/EventAnnouncement/GetAllNews`;
export const INSERT_NEWS = `${BASE_URL}/EventAnnouncement/InsertNews`;
export const UPDATE_NEWS = `${BASE_URL}/EventAnnouncement/UpdateNews`;
export const DELETE_NEWS = `${BASE_URL}/EventAnnouncement/DeleteNews`;

//img
export const POST_IMG = `${BASE_URL}/storage/PostImage`;

//excel
export const GET_EXCEL = `${BASE_URL}/storage/GetExcelMenuTemplate`;
export const GET_EXCEL_SERVICE = `${BASE_URL}/storage/GetExcelServiceTemplate`;
export const GET_EXCEL_EDU = `${BASE_URL}/storage/ExportEducationReport`;
export const GET_EXCEL_SURVEY = `${BASE_URL}/storage/ExportSurveyReport`;
export const GET_EXCEL_FEED = `${BASE_URL}/storage/ExportEAFeedbackReport`;
export const GET_EXCEL_SEEN = `${BASE_URL}/storage/ExportHasSeenReport`;
export const GET_EXCEL_SPECIAL_USER = `${BASE_URL}/storage/ExportUserStepReportById`;
export const GET_EXCEL_SPECIAL = `${BASE_URL}/storage/ExportSpecialTargetReport`;

//notification
export const NOTIFICATION_LIST = `${BASE_URL}/Notification/GetAllNotifications`;
export const NOTIFICATION_CONTENT_TYPE = `${BASE_URL}/Notification/GetNotificationContentTypeQuery`;
export const NOTIFICATION_ACTIVE_EVENTS = `${BASE_URL}/EventAnnouncement/getAllActiveEventAnnouncement`;
export const NOTIFICATION_ACTIVE_USERS = `${BASE_URL}/User/GetUserCountByUserFilterQuery`;
export const NOTIFICATION_INSERT = `${BASE_URL}/Notification/Insert`;
export const NOTIFICATION_DELETE = `${BASE_URL}/Notification/Delete`;
export const UPDATE_NOTIFICATION = `${BASE_URL}/Notification/Update`;

//phone
export const GET_PHONE = `${BASE_URL}/PhoneDirectory/GetPhoneDirectoryByTenantIdForWeb`;
export const GET_PHONE_BYID = `${BASE_URL}/PhoneDirectory/GetById`;
export const PHONE_INSERT = `${BASE_URL}/PhoneDirectory/ExcelImport`;
export const PHONE_ADD = `${BASE_URL}/PhoneDirectory/Insert`;
export const PHONE_UPDATE = `${BASE_URL}/PhoneDirectory/Update`;
export const PHONE_DELETE = `${BASE_URL}/PhoneDirectory/Delete`;
export const GET_EXCEL_PHONE_DIRECTORY_TEMPLATE = `${BASE_URL}/Storage/GetExcelPhoneDirectoryTemplate`;

//nearMiss section
export const GET_NEARSECTION = `${BASE_URL}/nearmisssection/GetAll`;
export const GET_NEARSECTION_BYID = `${BASE_URL}/nearmisssection/GetById`;
export const NEARSECTION_ADD = `${BASE_URL}/nearmisssection/Insert`;
export const NEARSECTION_UPDATE = `${BASE_URL}/nearmisssection/Update`;
export const NEARSECTION_DELETE = `${BASE_URL}/nearmisssection/Delete`;

//nearMiss subject
export const GET_NEARSUBJECT = `${BASE_URL}/nearmisssubject/GetAll`;
export const GET_NEARSUBJECT_BYID = `${BASE_URL}/nearmisssubject/GetById`;
export const NEARSUBJECT_ADD = `${BASE_URL}/nearmisssubject/Insert`;
export const NEARSUBJECT_UPDATE = `${BASE_URL}/nearmisssubject/Update`;
export const NEARSUBJECT_DELETE = `${BASE_URL}/nearmisssubject/Delete`;

//menu
export const GET_ALL_MENU = `${BASE_URL}/menu/GetAllMenu`;
export const DELETE_MENU_ROW = `${BASE_URL}/menu/DeleteMenuRow`;
export const MENU_INSERT = `${BASE_URL}/menu/InsertOrUpdate`;
export const GET_WEEKLY_MENU = `${BASE_URL}/menu/GetWeeklyMenu`;
export const GET_DAILY_MENU = `${BASE_URL}/menu/GetDailyMenuByDate`;

//shortcut
export const GET_ALL_SHORTCUT = `${BASE_URL}/shortcut/GetAll`;
export const SHORTCUT_INSERT = `${BASE_URL}/shortcut/Insert`;
export const SHORTCUT_UPDATE = `${BASE_URL}/shortcut/Update`;
export const SHORTCUT_DELETE = `${BASE_URL}/shortcut/Delete`;
export const GET_SHORTCUT_BYID = `${BASE_URL}/shortcut/GetById`;
export const SHORTCUT_INSERT_PDF = `${BASE_URL}/shortcut/PostPdf`;
export const GET_SHORTCUT_TYPE = `${BASE_URL}/shortcut/GetAllShortCutType`;

//service
export const GET_SERVICE_TYPE = `${BASE_URL}/ServiceType/GetAll`;
export const SERVICE_INSERT = `${BASE_URL}/ServiceManagement/ServiceInsertOrUpdate`;
export const GET_ALL_SERVICE = `${BASE_URL}/ServiceManagement/GetAllService`;
export const SERVICE_TYPR_INSERT = `${BASE_URL}/ServiceType/InsertOrUpdate`;
export const SERVICE_DELETE = `${BASE_URL}/ServiceManagement/ServiceDeleteRow`;
export const SERVICE_UPDATE = `${BASE_URL}/ServiceManagement/ServiceUpdateRow`;

//language
export const GET_ALL_LANGUAGE = `${BASE_URL}/language/GetAllLanguages`;
export const GET_LANGUAGE_BY_CODE = `${BASE_URL}/language/GetLanguageByCode`;
export const INSERT_LANGUAGE = `${BASE_URL}/language/Insert`;
export const UPDATE_LANGUAGE = `${BASE_URL}/language/Update`;
export const DELETE_LANGUAGE = `${BASE_URL}/language/Delete`;
export const GET_LANGUAGE_ITEMS_BY_CODE = `${BASE_URL}/localization/GetLocalizationsByLanguageCode`;
export const GET_ALL_LANGUAGE_ITEMS = `${BASE_URL}/localization/GetAllLocalizations`;
export const GET_ALL_LANGUAGE_ITEM_BY_KEY = `${BASE_URL}/localization/GetLocalizationByKey`;
export const INSERT_UPDATE_LANGUAGE_ITEM = `${BASE_URL}/localization/Insert`;

//questionnaire
export const QE_GET_ALL = `${BASE_URL}/Survey/GetAllSurveyWithDetail`;
export const QE_BASIC_GET_ALL = `${BASE_URL}/Survey/GetAllBasicSurveyList`;
export const QE_GET_CATEGORY = `${BASE_URL}/SurveyCategory/GetAllSurveyCategory`;
export const QE_UPDATE_QE = `${BASE_URL}/Survey/UpdateForAdmin`;
export const QE_INSERT = `${BASE_URL}/Survey/Insert`;
export const QE_DELETE = `${BASE_URL}/Survey/Delete`;
export const QE_UPDATE = `${BASE_URL}/Survey/Update`;
export const QE_EXCEL_TEMPLATE = `${BASE_URL}/Survey/GetExcelSurveyTemplate`;
export const QE_GET_BY_ID = `${BASE_URL}/Survey/GetSurveyById`;
export const QE_INSERT_EXCEL = `${BASE_URL}/Survey/InsertFromFile`;
export const QE_GET_ANSWER_TYPE = `${BASE_URL}/Question/GetAllAnswerType`;
export const QE_GET_ALL_BY_ID = `${BASE_URL}/Question/GetAllQuestionBySurveyId`;
export const QE_GET_REPORT = `${BASE_URL}/Survey/GetSurveyReport`;
export const QE_GET_ACTIVE = `${BASE_URL}/Survey/GetAllActiveSurvey`;

//education
export const EDU_GET_ALL = `${BASE_URL}/Education/GetAllEducation`;
// export const QE_BASIC_GET_ALL = `${BASE_URL}/Survey/GetAllBasicSurveyList`;
export const EDU_INSERT_CATEGORY = `${BASE_URL}/EducationCategory/Insert`;
export const EDU_INSERT = `${BASE_URL}/Education/Insert`;
export const EDU_DELETE = `${BASE_URL}/Education/Delete`;
export const EDU_UPDATE = `${BASE_URL}/Education/Update`;
export const EDU_CATEGORY_UPDATE = `${BASE_URL}/EducationCategory/Update`;
export const EDU_CATEGORY_DELETE = `${BASE_URL}/EducationCategory/Delete`;
// export const QE_GET_BY_ID = `${BASE_URL}/Survey/GetSurveyById`;
export const EDU_GET_CATEGORY = `${BASE_URL}/EducationCategory/GetAllForWeb`;
export const EDU_GET_BY_ID = `${BASE_URL}/Education/GetEducationByIdForAdmin`;
export const EDU_CATEGORY_GET_BY_ID = `${BASE_URL}/EducationCategory/GetById`;
export const EDU_GET_REPORT = `${BASE_URL}/Education/GetEducationReport`;
// export const QE_GET_REPORT = `${BASE_URL}/Survey/GetSurveyReport`;
export const EDU_GET_ACTIVE = `${BASE_URL}/Education/GetAllActiveEducation`;

//company
export const COMPANY_GET_LIST = `${BASE_URL}/ContractedCompany/GetAllContractedCompany`;
export const COMPANY_INSERT = `${BASE_URL}/ContractedCompany/Insert`;
export const COMPANY_DELETE = `${BASE_URL}/ContractedCompany/Delete`;
export const COMPANY_GET_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/GetAllContractedCompanyCategory`;
export const COMPANY_INSERT_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/Insert`;
export const COMPANY_GET_CATEGORY_ID = `${BASE_URL}/ContractedCompanyCategory/GetContractedCompanyCategoryById`;
export const COMPANY_UPDATE_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/Update`;
export const COMPANY_DELETE_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/Delete`;
export const COMPANY_GET_BY_ID = `${BASE_URL}/ContractedCompany/GetContractedCompanyById`;
export const COMPANY_UPDATE = `${BASE_URL}/ContractedCompany/Update`;

//social
export const SOCIAL_GET_LIST = `${BASE_URL}/socialclub/GetAll`;
export const SOCIAL_GET_USER_LIST = `${BASE_URL}/user/GetUserForDropdown`;
export const SOCIAL_INSERT = `${BASE_URL}/socialclub/Insert`;
export const SOCIAL_UPDATE = `${BASE_URL}/socialclub/Update`;
export const SOCIAL_GET_ID = `${BASE_URL}/socialclub/GetById`;
export const SOCIAL_DELETE = `${BASE_URL}/socialclub/Delete`;

//advertisement
export const AD_GET_ALL = `${BASE_URL}/Advertisement/GetAll`;
export const AD_GET_BY_ID = `${BASE_URL}/Advertisement/GetById`;
export const AD_INSERT = `${BASE_URL}/Advertisement/Insert`;
export const AD_UPDATE = `${BASE_URL}/Advertisement/Update`;
export const AD_DELETE = `${BASE_URL}/Advertisement/Delete`;
export const GET_AD_CATEGORY = `${BASE_URL}/AdCategory/GetAll`;
export const GET_AD_TYPE = `${BASE_URL}/AdType/GetAll`;

//contentCatogry
export const GET_ALL_CONTENT_CATEGORY = `${BASE_URL}/ContentCategory/GetAllContentCategory`;

//reservation
export const GET_ALL_RESERVATION = `${BASE_URL}/Reservation/GetAllReservation`;

//emergency
export const GET_ALL_EMERGENCY = `${BASE_URL}/userEmergency/GetAllUserEmergencies`;

//stepCounter
export const GET_ALL_STEPS = `${BASE_URL}/SpecialTarget/GetAll`;
export const GET_BY_ID_STEPS = `${BASE_URL}/SpecialTarget/GetById`;
export const DELETE_STEPS = `${BASE_URL}/SpecialTarget/Delete`;
export const INSERT_STEPS = `${BASE_URL}/SpecialTarget/Insert`;
export const UPDATE_STEPS = `${BASE_URL}/SpecialTarget/Update`;
export const STEPS_REPORT = `${BASE_URL}/SpecialTarget/GetReportById`;
export const STEPS_REPORT_USER = `${BASE_URL}/SpecialTarget/GetUserReportById`;

//
export const PERSON_IMAGE_FILE = `${BASE_URL}/storage/user-profile`;
export const TEAM_LOGO = `${BASE_URL}/storage/team-logo`;
export const POST_BASE64_IMAGE_FOR_MOBILE = `${BASE_URL}/Storage/PostBase64ImageForMobile`;

export const GET_WORKER_FOR_DROPDOWN = `${BASE_URL}/user/get-worker-for-dropdown`;
export const UPDATE_USER = `${BASE_URL}/user/Update`;
export const INSERT_USER = `${BASE_URL}/user/Insert`;
export const INSERT_USER_EXCEL = `${BASE_URL}/user/InsertWithExcel`;
export const EXPORT_EXCEL = `${BASE_URL}/user/GetAllUserForExcel`;
export const CREATE_USER = `${BASE_URL}/user/register`;
export const GET_CUSTOMER = `${BASE_URL}/user/get-customers`;
export const GET_AUTHORITYORDERS_DROPDOWN_ITEMS = `${BASE_URL}/user/dropdown-authorityorders`;
export const GET_COMMUNICATIONTYPES_DROPDOWN_ITEMS = `${BASE_URL}/user/dropdown-communicationtypes`;
export const GET_MANAGER_FOR_DROPDOWN = `${BASE_URL}/user/get-menager-for-dropdown`;

//retro
// export const GET_RETRO = `${BASE_URL}/retros`;

//role
export const GET_ACTIVE_ROLE = `${BASE_URL}/role/active-roles-for-dropdown`;
export const GET_ROLES = `${BASE_URL}/role/GetAllRoles`;
export const GET_ROLE_BY_ID = `${BASE_URL}/Role/GetById`;
export const ROLE_INSERT = `${BASE_URL}/role/Insert`;
export const ROLE_UPDATE = `${BASE_URL}/role/Update`;
export const ROLE_DELETE = `${BASE_URL}/role/Delete`;

//tenant
export const GET_TENANT = `${BASE_URL}/tenant/GetAllTenants`;
export const GET_TENANT_BY_ID = `${BASE_URL}/tenant/GetById`;
export const GET_ALL_TENANT = `${BASE_URL}/tenant/GetAllTenantList`;
export const INSERT_TENANT = `${BASE_URL}/tenant/Insert`;
export const DELETE_TENANT = `${BASE_URL}/tenant/Delete`;
export const UPDATE_TENANT = `${BASE_URL}/tenant/Update`;
export const TENANT_IMAGE = `${BASE_URL}/tenant/PostTenantLogo`;
export const GET_REPORT_DETAIL_BY_ID = `${BASE_URL}/tenant/GetReportDetailById`;
export const GET_REPORT_DETAIL_USER_COUNT = `${BASE_URL}/tenant/DepartmentTotalActiveUserCountsExcel`;

//Permission
export const GET_PERMISSION = `${BASE_URL}/permission/GetAllPermissionForCombo`;

//nearMissReport
export const GET_NEARMISS = `${BASE_URL}/nearmiss/GetAll`;
export const GET_NEARMISS_ID = `${BASE_URL}/nearmiss/GetById`;
export const GET_NEARMISS_ALL_ACTIVE = `${BASE_URL}/nearmiss/GetAllActiveNearMiss`;
export const GET_NEARMISS_ALL_BY_USER = `${BASE_URL}/nearmiss/GetAllActiveNearMissByUser`;
export const READ_NEAR_MISS = `${BASE_URL}/nearmiss/Read`;
export const PUT_NEAR_MISS = `${BASE_URL}/nearmiss/Update`;
export const GET_NEAR_MISS_EXCEL = `${BASE_URL}/nearmiss/ExportExcel`;

//app
export const GET_APP = `${BASE_URL}/ProjectDownloadUrl/GetAll`;
export const GET_APP_BY_ID = `${BASE_URL}/ProjectDownloadUrl/GetById`;
export const INSERT_OR_UPDATE_APP = `${BASE_URL}/ProjectDownloadUrl/InsertOrUpdate`;
export const POST_APP = `${BASE_URL}/ProjectDownloadUrl/PostIpaFile`;
export const GET_APP_URL = `${BASE_URL}/ProjectDownloadUrl/GetDownloadUrl`;

//project
export const GET_PROJECT_UNITS = `${BASE_URL}/project/dropdown-projectunits`;
export const GET_PROJECT_FOR_DROPDOWN = `${BASE_URL}/project/dropdown-projects`;
export const GET_PROJECT = `${BASE_URL}/project`;
export const GET_PROJECT_AGREEMENT_TYPES = `${BASE_URL}/project/dropdown-projectagreementtypes`;
export const GET_PROJECT_ADMINISTRATION_TYPES = `${BASE_URL}/project/dropdown-projectadministrationtypes`;

//title
export const GET_TITLES_FOR_DROPDOWN = `${BASE_URL}/title/get-titles-for-dropdown`;
export const TITLE_LIST_URL = `${BASE_URL}/title`;

//team
export const GET_TEAM = `${BASE_URL}/team`;
export const GET_TEAM_DROPDOWN = `${BASE_URL}/team/dropdown-teams`;
export const GET_TEAM_USERS_BY_TEAMID = `${BASE_URL}/team/get-users-by-teamid`;
export const ADD_USER_TO_TEAM = `${BASE_URL}/team/team-user-add`;
export const UPDATE_USER_TO_TEAM = `${BASE_URL}/team/team-user-update`;
export const DELETE_USER_TO_TEAM = `${BASE_URL}/team/team-user-delete`;
export const GET_USER_IN_TEAM = `${BASE_URL}/team/team-user-detail`;

//company
export const GET_COMPANY_DROPDOWN_ITEMS = `${BASE_URL}/company/dropdown-companies`;
export const COMPANY = `${BASE_URL}/company`;
export const GET_COUNTRY = `${BASE_URL}/company/dropdown-country`;
export const GET_DISTRICT = `${BASE_URL}/company/dropdown-district`;
export const UPDATE_COMPANY = `${BASE_URL}/company`;
export const DISTRICT_URL = `${BASE_URL}/company/getdistrictbycityid`;
export const CITY_URL = `${BASE_URL}/company/dropdown-city`;

//authority
export const AUTHORITY_URL = `${BASE_URL}/authority`;
export const AUTHORITY_TREE = `${BASE_URL}/authority/get-authorities-tree`;

//sprint
export const SPRINT_URL = `${BASE_URL}/sprint`;
export const SPRINT_STATUS = `${BASE_URL}/sprint/dropdown-sprintstatusenum`;

//task
export const TASK_URL = `${BASE_URL}/task`;
export const TASK_TYPE_URL = `${BASE_URL}/task/dropdown-tasktype`;
export const TASK_STATUS_URL = `${BASE_URL}/task/dropdown-taskstatus`;

//comment
export const COMMENT_URL = `${BASE_URL}/comment`;

//event (retro)
export const GET_ACTIVITY = `${BASE_URL}/retro/get-activity`;
export const GET_ACTIVITY_TYPE = `${BASE_URL}/retro/dropdown-activitytype`;
export const GET_ACTIVITY_NAME = `${BASE_URL}/retro/dropdown-activityname`;
export const ACTIVITY_URL = `${BASE_URL}/retro`;
export const ACTIVITY_DETAIL_URL = `${BASE_URL}/retro/get-detail`;
export const GET_RETRO = `${BASE_URL}/retro/get-retroreport-bysprintid`;
export const POST_RETRO = `${BASE_URL}/retro/retro-report`;
export const PUT_RETRO = `${BASE_URL}/retro/retroreport-put`;

//agreement
export const GET_AGREEMENT = `${BASE_URL}/agreement`;
export const GET_PROJECT_AGREEMENTS_TYPE = `${BASE_URL}/agreement/dropdown-for-projectagreementstype`;
export const GET_PROJECT_AGREEMENTSBY_TYPE = `${BASE_URL}/agreement/dropdown-for-projectagreementstype`;
export const GET_AGREEMENT_MAINTENANCE_TYPE = `${BASE_URL}/agreement/dropdown-for-maintenancetype`;
export const GET_CURRENCYTYPE = `${BASE_URL}/agreement/dropdown-for-currencytype`;
export const GET_STAMP_TAX_TYPE = `${BASE_URL}/agreement/dropdown-for-stamptaxtype`;
export const KDV_EXEPTION_TYPE = `${BASE_URL}/agreement/dropdown-for-kdvexemptiontype`;
export const AGREEMENT_PDF_FILE = `${BASE_URL}/storage/agreement`;
export const BILLINGPLAN_URL = `${BASE_URL}/billingplan`;
export const AGREEMENT_TYPE = `${BASE_URL}/agreement/dropdown-for-agreementtype`;

//version
export const VERSION_URL = `${BASE_URL}/version`;

// CORDLYNX Identity Management System API

// Account
export const ACCOUNT = `${BASE_URL}/account/`;
export const ADD_POSITION_TO_ACCOUNT = `${BASE_URL}/account/position/`;
export const GET_ACCOUNT_LIST = `${BASE_URL}/account/query`;
export const ACCOUNT_REQUEST = `${BASE_URL}/account/request`;

// Auth
export const LOGIN_CORDLYNX = `${BASE_URL}/auth/login`;
export const REFRESH_TOKEN = `${BASE_URL}/auth/refresh-token`;
export const SEND_EMAIL = `${BASE_URL}/auth/send-code`;
export const SEND_EMAIL_CODE = `${BASE_URL}/auth/verify-code`;
export const RESET_PASSWORD = `${BASE_URL}/auth/reset-password`;
export const REGISTER_CORDLYNX = `${BASE_URL}/auth/register`;

// Company
export const COMPANY_CORDLYNX = `${BASE_URL}/company/`;
export const GET_COMPANY_LIST = `${BASE_URL}/company/query`;
export const INSERT_CONTACT_TO_COMPANY = `${BASE_URL}/company/contact`;

// CompanyGroup
export const COMPANY_GROUP = `${BASE_URL}/companyGroup`;
export const INSERT_COMPANY_TO_COMPANY_GROUP = `${BASE_URL}/companyGroup/addCompany`;
export const GET_COMPANY_GROUP_LIST = `${BASE_URL}/companyGroup/query`;

// Contact
export const CONTACT = `${BASE_URL}/contact/`

// Dev
export const GENERATE_API_KEY = `${BASE_URL}/dev/generate-api-key`;

// Follow
export const FOLLOW = `${BASE_URL}/follow`;
export const FOLLOW_PROCESS = `${BASE_URL}/follow/process`;

// Mail
export const SEND_MAIL = `${BASE_URL}/mail`;
export const MAIL_TEMPLATE = `${BASE_URL}/mail/template`;
export const GET_MAIL_TEMPLATE_LIST = `${BASE_URL}/mail/template/query`;

// Organization
export const ORGANIZATION = `${BASE_URL}/organization`;
export const GET_ORGANIZATION_LIST = `${BASE_URL}/organization/query`;
export const ORGANIZATION_POSITION = `${BASE_URL}/organization/position`;
export const GET_POSITION_LIST = `${BASE_URL}/organization/position/query/`;

// Organization Group
export const ORGANIZATION_GROUP = `${BASE_URL}/organizationGroup`;
export const GET_ORGANIZATION_GROUP_LIST = `${BASE_URL}/organizationGroup/query/`;

// Permission
export const ACCOUNT_GROUP = `${BASE_URL}/permission/accountGroup`;
export const GET_ACCOUNT_GROUP_LIST = `${BASE_URL}/permission/accountGroup/query`;
export const ACCOUNT_TYPE = `${BASE_URL}/permission/accountType/`;
export const INSERT_ROLE_TO_ACCOUNT_TYPE = `${BASE_URL}/permission/account-type/role/add`;
export const DELETE_ROLE_FROM_ACCOUNT_TYPE = `${BASE_URL}/permission/account-type`;
export const GET_ACCOUNT_TYPE_LIST = `${BASE_URL}/permission/accountType/query`;
export const ROLE = `${BASE_URL}/permission/role`;
export const GET_ROLE_LIST = `${BASE_URL}/permission/role/query`;
export const ROLE_TYPE = `${BASE_URL}/permission/roleType`;
export const GET_ROLE_TYPE_LIST = `${BASE_URL}/permission/roleType/query`;
export const UPDATE_ALL_ROLES = `${BASE_URL}/permission/account-type/`;

// Settings
export const SETTINGS = `${BASE_URL}/settings`;
export const GET_SETTINGS_LIST = `${BASE_URL}/settings/query`;
export const SETTINGS_DATATYPE = `${BASE_URL}/settings/dataType`;
export const GET_SETTINGS_DATATYPE_LIST = `${BASE_URL}/settings/dataType/query`;
export const SETTINGS_PROGRAM = `${BASE_URL}/settings/program`;
export const GET_SETTINGS_PROGRAM_LIST = `${BASE_URL}/settings/program/query`;
export const SETTINGS_SMTP = `${BASE_URL}/settings/smtp`;
export const GET_SETTINGS_SMTP_LIST = `${BASE_URL}/settings/smtp/query`;
export const SETTINGS_TYPE = `${BASE_URL}/settings/type`;
export const GET_SETTINGS_TYPE_LIST = `${BASE_URL}/settings/type/query`;
export const USER_SETTINGS = `${BASE_URL}/settings/user`;
export const GET_USER_SETTINGS_LIST = `${BASE_URL}/settings/user/query`;

// Test
export const TEST = `${BASE_URL}/test`;

// Cordlynx API

// Bot Answer
export const INSERT_BOT_ANSWER = `${SLM_URL}/botAnswer/create`;
export const DELETE_BOT_ANSWER = `${SLM_URL}/botAnswer/delete`;
export const GET_BOT_ANSWER_BY_ID = `${SLM_URL}/botAnswer/getById`;
export const UPDATE_BOT_ANSWER = `${SLM_URL}/botAnswer/update`;

// Bot Question
export const GET_BOT_QUESTION_BY_ID = `${SLM_URL}/botQuestion`;
export const INSERT_BOT_QUESTION = `${SLM_URL}/botQuestion/create`;
export const DELETE_BOT_QUESTION = `${SLM_URL}/botQuestion/delete`;
export const UPDATE_BOT_QUESTİON = `${SLM_URL}/botQuestion/update`;
export const GET_BOT_QUESTION_LIST = `${SLM_URL}/botQuestion/getQuestions`;

// Document
export const GET_DOCUMENT = `${SLM_URL}/Document/GetDocument/`;
export const DOWNLOAD_DOCUMENT = `${SLM_URL}/Document/DownloadDocument/`;
export const DELETE_DOCUMENT = `${SLM_URL}/Document/DeleteDocument/`;
export const UPLOAD_DOCUMENT = `${SLM_URL}/Document/UploadDocument/`;

// Excel Reader
export const UPLOAD_SLA_DATA = `${SLM_URL}/ExcelReader/uploadSlaData`;

// Kpi
export const INSERT_KPI_TO_SLA = `${SLM_URL}/Kpi/AddKpiToSla`;
export const INSERT_KPI = `${SLM_URL}/Kpi/CreateKpi`;
export const DELETE_KPI_BY_ID = `${SLM_URL}/Kpi/DeleteKpiById`;
export const DELETE_KPI_FROM_SLA_BY_ID = `${SLM_URL}/Kpi/DeleteKpiFromSlaById`;
export const GET_ALL_KPI_LIST = `${SLM_URL}/Kpi/GetAllKpi`;
export const GET_KPI_BY_COMPANY_ID = `${SLM_URL}/Kpi/GetKpiByCompanyId/`;
export const GET_ALL_KPI_AND_DETAILS = `${SLM_URL}/kpi/details/`;
export const GET_KPI_BY_ID = `${SLM_URL}/Kpi/GetKpiById/`;
export const PURGE_KPI_BY_ID = `${SLM_URL}/Kpi/PurgeKpiById`;
export const UPDATE_KPI_BY_ID = `${SLM_URL}/Kpi/UpdateKpiById`;
export const CLONE_KPI = `${SLM_URL}/Kpi/CloneKpi`;

// Kpi Category
export const INSERT_KPI_CATEGORY = `${SLM_URL}/KpiCategory/CreateKpiCategory`;
export const DELETE_KPI_CATEGORY = `${SLM_URL}/KpiCategory/DeleteKpiCategory`;
export const GET_ALL_KPI_CATEGORY_LIST = `${SLM_URL}/KpiCategory/GetAllKpiCategory`;
export const GET_KPI_CATEGORY_BY_ID = `${SLM_URL}/KpiCategory/GetKpiCategoryById`;
export const PURGE_DELETE_KPI_CATEGORY = `${SLM_URL}/KpiCategory/PurgeDeleteKpiCategory`;
export const UPDATE_KPI_CATEGORY = `${SLM_URL}/KpiCategory/UpdateKpiCategory`;

// Priority
export const INSERT_PRIORITY = `${SLM_URL}/Priority/CreatePriority`;
export const DELETE_PRIORITY = `${SLM_URL}/Priority/DeletePriority`;
export const GET_ALL_PRIORITY_LIST = `${SLM_URL}/Priority/GetAllPriority`;
export const GET_PRIORITY_BY_ID = `${SLM_URL}/Priority/GetPriorityById`;
export const PURGE_DELETE_PRIORITY = `${SLM_URL}/Priority/PurgeDeletePriority`;
export const UPDATE_PRIORITY = `${SLM_URL}/Priority/UpdatePriority`;

// Service
export const INSERT_SERVICE_TO_SLA = `${SLM_URL}/Service/AddToSla`;
export const INSERT_SERVICE = `${SLM_URL}/Service/CreateService`;
export const DELETE_SERVICE = `${SLM_URL}/Service/DeleteService`;
export const GET_SERVICE_BY_COMPANY_ID = `${SLM_URL}/Service/get-service-by-company-id/`;
export const GET_ALL_SERVICE_LIST = `${SLM_URL}/Service/GetAllService/`;
export const GET_SERVICE_BY_ID = `${SLM_URL}/Service/GetServiceById/`;
export const REMOVE_SERVICE_FROM_SLA = `${SLM_URL}/Service/RemoveFromSla`;
export const UPDATE_SERVICE_BY_ID = `${SLM_URL}/Service/UpdateServiceById`;
export const UPDATE_SERVICE_DATA_BY_ID = `${SLM_URL}/service/data/`;
export const UPDATE_SERVICES = `${SLM_URL}/Service/UpdateServices`;
export const UPDATE_SERVICE_VISIBILITY = `${SLM_URL}/Service/UpdateServiceVisibilityByCompanyId`;

// SLA
export const INSERT_SLA = `${SLM_URL}/Sla/CreateSla`;
export const DELETE_SLA = `${SLM_URL}/Sla/DeleteSla`;
export const GET_ALL_SLA_LIST = `${SLM_URL}/Sla/GetAllSla`;
export const GET_ALL_SLA_LIST_BY_ACCOUNT_ID = `${SLM_URL}/Sla/GetAllByAccountId`;
export const GET_ALL_SLA_LIST_BY_ACCOUNT_ID_AND_COMPANY_ID = `${SLM_URL}/Sla/GetAllSlaByAccountIdAndCompanyId`;
export const GET_ALL_SLA_LIST_BY_COMPANY_ID_AND_COMPANY_GROUP_ID = `${SLM_URL}/Sla/GetAllSlaByCompanyIdAndCompanyGroupId`;
export const GET_KPI_AND_TARGET = `${SLM_URL}/Sla/GetKpiAndTarget`;
export const GET_SLA_BY_ID = `${SLM_URL}/Sla/GetSlaById`;
export const GET_SLA_BY_KIND = `${SLM_URL}/Sla/GetSlaByKind`;
export const GET_SLA_BY_OKR = `${SLM_URL}/Sla/GetSlaByOKR`;
export const GET_SLA_STATISTICS_BY_START_AND_FINISH_DATE = `${SLM_URL}/Sla/GetSlaStatisticsByStartAndFinishDate`;
export const GET_TARGET_STATISTICS = `${SLM_URL}/Sla/GetTargetStatistics`;
export const PURGE_SLA = `${SLM_URL}/Sla/PurgeSla`;
export const UPDATE_SLA = `${SLM_URL}/Sla/UpdateSla/`;
export const GET_SLA_ORGANIZATIONS = `${SLM_URL}/Sla/Query`;

// AGREEMENT
export const COPY_OR_CREATE_AGREEMENT = `${SLM_URL}/Agreement/CopyOrCreateAgreement`;
export const CREATE_AGREEMENT = `${SLM_URL}/Agreement/CreateAgreement`;
export const BIND_KPI_TO_AGREEMENT = `${SLM_URL}/Agreement/BindKpiToAgreement`;
export const SIGN_AGREEMENTS = `${SLM_URL}/Agreement/SignAgreements`;
export const AGREEMENT_STATS = `${SLM_URL}/Agreement/AgreementStatistic`;

// SLA Service
export const GET_ALL_SERVICE_LIST_BY_SLA_ID = `${SLM_URL}/SlaService/GetAllServicesBySlaId`;

// SLA Type
export const INSERT_SLA_TYPE = `${SLM_URL}/SlaType/CreateSlaType`;
export const DELETE_SLA_TYPE = `${SLM_URL}/SlaType/DeleteSlaType`;
export const GET_ALL_SLA_TYPE_LIST = `${SLM_URL}/SlaType/GetAllSlaType`;
export const GET_SLA_TYPE = `${SLM_URL}/SlaType/GetSlaType`;
export const UPDATE_SLA_TYPE = `${SLM_URL}/SlaType/UpdateSlaType`;

// Target
export const INSERT_TARGET = `${SLM_URL}/Target/CreateTarget`;
export const DELETE_TARGET_BY_ID = `${SLM_URL}/Target/DeleteTargetById`;
export const GET_ALL_TARGET_LIST = `${SLM_URL}/Target/GetAllTarget`;
export const GET_TARGET_BY_ID = `${SLM_URL}/Target/GetTargetById`;
export const PURGE_TARGET_BY_ID = `${SLM_URL}/Target/PurgeTargetById`;
export const UPDATE_TARGET_BY_ID = `${SLM_URL}/Target/UpdateTargetById`;
export const UPDATE_TARGETS_LIST = `${SLM_URL}/Target/UpdateTargets`;

// Target Type
export const INSERT_TARGET_TYPE = `${SLM_URL}/TargetType/CreateTargetType`;
export const DELETE_TARGET_TYPE = `${SLM_URL}/TargetType/DeleteTargetType`;
export const GET_ALL_TARGET_TYPE_LIST = `${SLM_URL}/TargetType/GetAllTargetType`;
export const GET_TARGET_TYPE_BY_ID = `${SLM_URL}/TargetType/GetTargetTypeById`;
export const UPDATE_TARGET_TYPE = `${SLM_URL}/TargetType/UpdateTargetType`;

// Verified SLA
export const VERIFIED_SLA = `${SLM_URL}/verified-sla/`;

// Verified SLA'S
export const VERIFIED_SLAS = `${SLM_URL}/verified-slas`;

// Test
export const CORDLYNX_API_TEST = `${SLM_URL}/test`;
