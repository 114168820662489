/* eslint-disable react-hooks/exhaustive-deps */
import { tryCatch } from "@thalesrc/js-utils";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useStateStorage } from "../hooks/use-state-storage";
import { getCustomer, getLogout, loginUserCode, phoneVerify } from "../service/auth";
import { loginUser } from "../service/cordlynxServices/auth";
import pageURL from "../utils/pageUrls";
import { AppContext } from './AppContext';
import { LoadingContext } from "./Loading";
import { ToasterContext } from "./ToasterContext";
export const UserContext = createContext(null);
export function UserContextProvider({ children }) {
  const history = useHistory();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { openToaster } = useContext(ToasterContext);
  const [token, setToken, removeToken] = useStateStorage("token");
  const [accessToken, setAccessToken, removeAccessToken] = useStateStorage("accessToken");
  const [userStorage, setUserStorage, removeUserStorage] = useStateStorage("user");
  const { removeSelectedFactory, removeSelectedRoles } = useContext(AppContext);

  const removeStorage = (() => {
    removeSelectedRoles("selectedRoles");
    removeSelectedFactory("selectedFactory");
    removeToken("token");
    removeUserStorage("user");
    removeAccessToken("accessToken");
  });

  const getUserInfo = async () => {
    getCustomer().then((response) => {
      setUserStorage(response.data);
      hideLoading();
    }).catch((error) => {
      removeStorage();
      hideLoading();
      throw error;
    });
  };

  const authToken = async (payload) => {
    const [err, res] = await tryCatch(loginUser(payload));
    if (err)
      // openToaster("top-right", { severity: "error", summary: "Bir Sorun Oluştu", detail: "Tekrar Deneyiniz", life: 3000 });
      return;
    if (res?.access_token)
      setAccessToken(res.access_token);
  };

  const requestPhoneVerify = async (payload) => {
    const [err, res] = await tryCatch(phoneVerify(payload));
    hideLoading();
    if (err) {
      openToaster("top-right", { severity: "error", summary: "Hatalı Giriş", detail: "Bilgilerinizi Kontrol Ediniz", life: 3000 });
      return err;
    }
    return res?.data
  };

  const requestLogin = async (payload, setApiResult) => {
    showLoading();
    const [err, res] = await tryCatch(loginUser(payload));
    hideLoading();
    if (err) {
      //openToaster("bottom-right", { severity: "error", summary: "Hatalı Giriş", detail: err?.errors?.generalErrors?.[0] , life: 3000 });
      setApiResult(true)
      return;
    }
    if (res?.accessToken) {
      const token = { accessToken: res.accessToken, refreshToken: res.refreshToken };
      await setToken(token);
      // getUserInfo();
      localStorage.removeItem("accessToken");
      setUserStorage(res);
      history.push(pageURL.home);
      return res;
    }
  }

  const logout = useCallback(async () => {
    const [err] = await tryCatch(getLogout());
    if (err) {
      openToaster("top-right", { severity: "error", summary: "Bir Sorun Oluştu", detail: "İnternet Bağlantınızı Kontrol Ediniz", life: 3000 });
      hideLoading();
      return;
    }
    removeStorage();
    history.push(pageURL.login);
  }, [history, removeToken]);

  const context = useMemo(() => ({ token, accessToken, userStorage, authToken, requestLogin, requestPhoneVerify, logout, setToken, getUserInfo }), [userStorage, accessToken, token, authToken, logout, requestPhoneVerify, setToken, getUserInfo]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}
