import { tryCatch } from "@thalesrc/js-utils";
import { 
    INSERT_KPI_TO_SLA,
    INSERT_KPI,
    DELETE_KPI_BY_ID,
    DELETE_KPI_FROM_SLA_BY_ID,
    GET_ALL_KPI_LIST,
    GET_KPI_BY_COMPANY_ID,
    GET_KPI_BY_ID,
    PURGE_KPI_BY_ID,
    UPDATE_KPI_BY_ID,
    GET_ALL_KPI_AND_DETAILS,
    CLONE_KPI
} from "../../utils/url";
import http from "../http";
import { pathWithQueryString } from "../../helpers/pathWithQueryString";

export async function insertKpi(payload) {
    const url = pathWithQueryString(INSERT_KPI)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function insertKpiToSla(payload) {
    const url = pathWithQueryString(INSERT_KPI_TO_SLA)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function deleteKpiById(payload) {
    const url = pathWithQueryString(DELETE_KPI_BY_ID)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function removeKpiFromSlaById(payload) {
    const url = pathWithQueryString(DELETE_KPI_FROM_SLA_BY_ID)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data;
}

export async function getAllKpiList(payload) {
    const url = pathWithQueryString(GET_ALL_KPI_LIST,payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data;
}

export async function getAllKpiAndDetailsList(payload) {
    const url = pathWithQueryString(GET_ALL_KPI_AND_DETAILS,payload)

    const [error, result] = await tryCatch(http.get(url));

    if (error) throw error;
  
    return result.data;
}

export async function getKpiById(payload) {
    const url = pathWithQueryString(GET_KPI_BY_ID, payload)

    const [error, result] = await tryCatch(http.get(url, payload));
    if (error) throw error;
  
    return result.data.data;
}

export async function getAllKpiByCompanyId(payload) {
    const url = pathWithQueryString(GET_KPI_BY_COMPANY_ID, payload)

    const [error, result] = await tryCatch(http.get(url, payload));
    console.log("Res",result)
    if (error) throw error;
  
    return result.data.items;
}

export async function purgeKpiById(payload) {
    const url = pathWithQueryString(PURGE_KPI_BY_ID)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function updateKpiById(payload) {
    const url = pathWithQueryString(UPDATE_KPI_BY_ID)

    const [error, result] = await tryCatch(http.put(url, payload));

    if (error) throw error;
  
    return result.data.data;
}

export async function cloneKpi(payload) {
    const url = pathWithQueryString(CLONE_KPI)

    const [error, result] = await tryCatch(http.post(url, payload));

    if (error) throw error;
  
    return result.data.data;
}