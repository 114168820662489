import { formatDate } from "@fullcalendar/core";

export const formatDateTR = (date) => {
  return formatDate(date, { locale: "tr" });
};

export const formatDateTRHour = (date) => {
  return formatDate(date, {
    month: "long",
    year: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    locale: "tr",
  });
};

export const formatDateTRMonth = (date) => {
  return formatDate(date, {
    month: "numeric",
    year: "numeric",
  });
};

export const formattedDate = (date) =>
  date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    timeZoneName: "short",
  });

  export const formatDateTRLong = (date) => {
    return formatDate(date, {
      month: "long",
      year: "numeric",
      day: "numeric",
      locale: "tr",
    });
  };

 export const formatDateTRMonthLong = (date) => {
    return formatDate(date, {
      month: "long",
      year: "numeric",
      locale: "tr",
    });
  }

 export const formatDateUTC = (date) => {
    const d = new Date(date);
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  };